@import '../../../styles/_variables.scss';

.location-latest-message-v2 {
  padding: 48px 0px;
  position: relative;

  .latest-message-bg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top:0;
    width: 100%;
    z-index: -1;

    .backdrop {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
      height: 100%;
      width: 100%;
    }
  }

  .container {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .latest-message-header {
    margin-bottom: 32px;
    max-width: 450px;

    h2 {
      font-size: 32px;
      font-weight: 900;
      line-height: var(--line-height-tighter);
    }
  }

  .content {
    max-width: 450px;
    padding: 0px 16px;

    >div {
      border: 1px solid var(--gray-30);
      border-radius: 4px;
      filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.6));
      position: relative;

      img {
        border-radius: inherit;
        display: block;
      }

      .latest-message-play-btn {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        &:hover {
          text-decoration: none;
        }

        .icon {
          color: var(--gray);
          font-size: 32px;
        }
      }
    }
  }
}

@media screen and (min-width: $md) {
  .location-latest-message-v2 {
    padding: 40px 0px;

    .content {
      padding: 0px;
    }
  }
}

@media screen and (min-width: $lg) {
  .location-latest-message-v2 {
    padding: 123px 0px;

    .container {
      column-gap: 112px;
      flex-direction: row-reverse;
      justify-content: space-evenly;
    }

    .latest-message-header {
      max-width: 500px;

      h2 {
        font-size: 64px;
        line-height: var(--line-height-tightest);
      }
    }

    .content {
      width: 100%;
    }
  }
}
