@import '../../../styles/_variables.scss';

.location-lc-for-you-v2 {
  display: flex;
  flex-direction: column;
  padding: 48px 0px;
  row-gap: 72px;

  .shared-section {
    flex-grow: 1;

    .container {
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    .section-header {
      margin-bottom: 24px;
      max-width: 450px;

      h2 {
        font-size: 32px;
        font-weight: 900;
        line-height: var(--line-height-tighter);
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      max-width: 450px;
      row-gap: 32px;

      >div {
        &:nth-child(1) {
          img {
            display: block;
          }
        }

        &:nth-child(2) {
          display: flex;
          flex-direction: column;
          row-gap: 16px;

          h3 {
            font-size: 24px;
            font-weight: 700;
            line-height: var(--line-height-tighter);
          }

          p {
            font-size: 16px;
            font-weight: 400;
            line-height: var(--line-height-normal);
          }

          .section-action-btns {
            display: flex;
            flex-direction: column;
            row-gap: 16px;
          }
        }
      }
    }
  }

  .lc-switch {
    padding: 16px 0px 72px;

    .container {
      align-items: center;
      display: flex;
      flex-direction: column;

      > img {
        margin-bottom: 32px;
        max-width: 450px;
      }

      .content {
        max-width: 450px;

        h2 {
          font-size: 24px;
          font-weight: 700;
          line-height: var(--line-height-tighter);
          margin-bottom: 16px;
        }

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: var(--line-height-normal);
          margin-bottom: 16px;
        }

        .switch-events {
          margin-bottom: 24px;

          h3 {
            color: var(--gray-5);
            font-size: 24px;
            font-weight: 700;
            line-height: var(--line-height-tighter);
            margin-bottom: 8px;
          }

          span {
            background-color: var(--grayscale);
            display: inline-block;
            font-size: 20px;
            font-weight: 700;
            line-height: var(--line-height-normal);
            padding: 4px 8px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $md) {
  .location-lc-for-you-v2 {
    padding: 64px 0px;
    row-gap: 64px;

    .shared-section {
      .section-header {
        margin-bottom: 32px;
      }

      .content {
        >div {
          &:nth-child(1) {
            img {
              height: 254px;
              object-position: top;
            }
          }

          &:nth-child(2) {
            .section-action-btns {
              align-items: flex-start;
            }
          }
        }
      }
    }

    .lc-switch {
      background-image: url(../../LocationV2/assets/SwitchMedium.png);
      background-position-x: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 177px 0px 40px;

      .content {
        >div:last-of-type {
          display: flex;
        }
      }
    }
  }
}

@media screen and (min-width: $lg) {
  .location-lc-for-you-v2 {
    padding: 112px 0px;
    row-gap: 112px;

    .shared-section {
      .section-header {
        margin-bottom: 64px;
        max-width: 100%;
        width: 100%;

        h2 {
          font-size: 64px;
          line-height: var(--line-height-tightest);
        }
      }

      .content {
        align-items: center;
        column-gap: 64px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 100%;
        width: 100%;

        >div {
          &:nth-child(1) {
            flex: 1;
            max-width: 552px;

            img {
              height: 346px;
            }
          }

          &:nth-child(2) {
            flex: 1;
            max-width: 552px;

            p {
              font-size: 20px;
              margin-bottom: 16px;
            }
          }
        }
      }
    }

    .for-you {
      .content {
        flex-direction: row-reverse;
      }
    }

    .for-your-family {
      .content {
        > div {
          &:nth-child(2) {
            .section-action-btns {
              column-gap: 16px;
              flex-direction: row;
            }
          }
        }
      }
    }

    .lc-switch {
      background-image: url(../../LocationV2/assets/SwitchLarge.png);
      background-position-x: right;
      padding: 112px 0px;

      .container {
        align-items: flex-start;

        .content {
          max-width: 552px;
          width: 552px;

          img {
            margin-bottom: 48px;
            max-width: 300px;
          }

          h2 {
            margin-bottom: 32px;
          }

          p {
            font-size: 20px;
            margin-bottom: 32px;
          }

          .switch-events {
            margin-bottom: 32px;
          }
        }
      }
    }
  }
}
