.signature {
  display: flex;
  flex-direction: column;
  text-align: left;

  .signature-input {
    border: 10px solid #f6f6f6;
    border-radius: 8px;
    box-sizing: border-box;
    height: 150px;

    .sigCanvas {
      height: 100%;
      width: 100%;
    }
  }

  label {
    color: var(--grayscale);
    font-size: var(--f-paragraph_large);
    font-weight: bold;
    line-height: var(--line-height-normal);
  }

  textarea {
    border: 1px solid #c6c6c6;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: var(--f-paragraph_large);
    padding: 15px;
  }

  span {
    color: var(--grayscale);
  }
}
