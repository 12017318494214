@import '../../../styles/_variables.scss';
@import './AboutAlbumAndSong.scss';
@import './AlbumCardListing.scss';
@import './ChurchResources.scss';
@import './CollectionAlbumHero.scss';
@import './CollectionListingAlbumHero.scss';
@import './ContentAndForm.scss';
@import './CurrentSeries.scss';
@import './GetUpdates.scss';
@import './InThisSeries.scss';
@import './PastSeries.scss';
@import './PodcastHero.scss';
@import './PodcastLeadGenerationForm.scss';
@import './PodcastQuote.scss';
@import './PodcastAccordion.scss';
@import './PodcastContentAndSubscribe.scss';
@import './PodcastContentFormModal.scss';
@import './PodcastQuoteModal.scss';
@import './PromoVideo.scss';
@import './SongDetail.scss';
@import './PodcastCollectionHero.scss';
@import './PodcastTextAndImage.scss';
@import './PodcastCollectionSubscribe.scss';
@import './PodcastCollectionContentForm.scss';
@import './TalkItOver.scss';
@import './FindArticleListing.scss';
@import './RelatedContentCarousel.scss';
@import './MediaDetailSeriesBased.scss';
@import './PodcastFilter.scss';

img {
  object-fit: cover;
}

.override-btn {
  width: 332px;
}

.override-btn-modal {
  @media screen and (min-width: $md) {
    .modal {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.media-detail,
.media-collection {
  .youtube-wrapper {
    background-color: #404041;
    margin-bottom: var(--spacing-normal);

    .youtube-video-wrapper {
      margin: 0 auto;
      max-width: 1000px;
    }
  }
}

.fallback-player {
  background-color: #404041;
  margin-bottom: var(--spacing-normal);

  .life-church-bitmovin-player {
    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;
  }
}

@media screen and (min-width: $md) {
  .media-detail {
    .youtube-wrapper {
      margin-bottom: var(--spacing-bit_more_relaxed);
    }
  }

  .fallback-player {
    margin-bottom: var(--spacing-bit_more_relaxed);
  }

  .social-media-share {
    .button-item-listing {
      .anchor-link {
        .path2,
        .path3,
        .path4,
        .path5 {
          &::before {
            color: #00b3e5;
          }
        }
      }
    }
  }
}
