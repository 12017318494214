@import '../../styles/_variables.scss';
.carousel-item {
  &.link {
    color: unset;
    text-decoration: none;
  }

  &.modal-item {
    cursor: pointer;
  }

  .carousel-item-image {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &.general-carousel {
    height: 210px;
    width: inherit;
  }

  &.album-carousel {
    height: 200px;

    @media only screen and (min-width: $lg) {
      width: 200px;
    }
  }

  &.text-carousel {
    display: flex;
    margin: 0 auto;
    width: calc(100% - 88px);

    .rich-text {
      padding: 0;
    }

    .carousel-item-image-container {
      margin-bottom: auto;
      margin-top: auto;
      max-height: 264px;
      max-width: 264px;
      min-width: 264px;

      @media only screen and (max-width: $lg) {
        max-height: 200px;
        max-width: 264px;
        min-width: 200px;
        width: 30%;
      }
    }

    .carousel-item-image {
      border-radius: 50%;
    }

    .carousel-item-content {
      padding-left: 32px;
      white-space: normal;

      .carousel-item-title {
        font-size: 24px;
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }
}

@media only screen and (max-width: 639px) {
  .carousel-item {
    &.general-carousel {
      height: 150px;
      width: inherit;
    }

    &.album-carousel {
      height: 131px;
    }

    &.text-carousel {
      align-items: center;
      flex-direction: column;
      text-align: center;
      width: 100%;

      .carousel-item-title {
        margin-top: unset !important;
      }

      .carousel-item-image-container {
        max-height: unset;
        max-width: 264px;
        min-width: unset;
        width: 100%;
      }

      .carousel-item-content {
        padding: 0;
      }
    }
  }
}

@media only screen and (max-width: $lg) and (min-width: $md) {
  .carousel-item {
    &.general-carousel {
      height: 170px;
      width: inherit;
    }

    &.album-carousel {
      height: 138px;
      width: 138px;
    }

    &.text-carousel {
      .carousel-item-image {
        margin-bottom: unset;
      }
    }
  }
}
