@import '../../styles/_variables.scss';

.ancher-navigation {
  background-color: #4ba5d3;

  &.text-center {
    .nav-list {
      justify-content: center;
    }
  }

  &.text-left {
    .nav-list {
      justify-content: flex-start;
    }
  }

  &.text-right {
    .nav-list {
      justify-content: flex-end;
    }
  }

  .title {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
    text-align: center;
  }

  .mobile-active {
    padding: 16px;
    img {
      margin-left: 8px;
      width: 8px;
    }
  }

  .nav-list {
    background-color: rgba(0, 158, 204, 0.2);
    display: none;

    &.active {
      display: block;
    }

    li {
      border-top: 1px solid #fff;

      a {
        color: #2e6c8a;
        display: block;
        padding: 16px;
        text-decoration: none;

        &:hover {
          color: #fff;
        }
      }
    }
  }

  @media screen and (min-width: $md) {
    .mobile-active {
      display: none;
    }

    .nav-list {
      display: flex;
      padding: 0 16px;

      &.active {
        display: flex;
      }

      li {
        border-top: none;
        display: inline-block;

        a {
          padding-left: 0;
          padding-right: 0;

          &.active {
            color: #fff;
          }
        }
      }

      /** Used instead of column-gap, because of Safari ¯\_(ツ)_/¯ **/
      & > * + * {
        margin-left: 64px;
      }
    }
  }
}
