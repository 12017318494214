@import '../../../styles/_variables.scss';

.album-cards-listing {
  column-gap: 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 16px;

  .album-card-item {
    margin-bottom: var(--spacing-normal);

    &:hover {
      .album-card-image-link {
        box-shadow: 0px 3px 12px 0px lightgray;
        transform: translate(0, -4px);
      }

      .album-card-name {
        color: #0ea9d6;
      }
    }

    .album-card-image {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 4px;
      padding-bottom: 100%;
      width: 100%;
    }

    .album-card-image-link {
      transition: box-shadow 0.5s, transform 0.5s;
      width: 100%;
    }

    .album-card-name {
      font-size: 20px;
      line-height: var(--line-height-normal);
      margin: var(--spacing-tighter) 0 0;
    }

    .album-card-subtitle {
      color: #666666;
      line-height: var(--line-height-normal);
      margin: 0;
    }
  }
}

@media screen and (min-width: $md) {
  .album-cards-listing {
    grid-template-columns: repeat(4, 1fr);

    .album-card-name {
      font-size: 20px;
    }
  }
}
