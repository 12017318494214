@import '../../../styles/_variables.scss';
.podcast-collection,
.media-detail-podcast-based {
  .podcast-content-form-modal {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 100;

    .modal-header {
      background-color: #404041;
      display: flex;
      height: 50px;
      justify-content: flex-end;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      .btn-exit {
        svg {
          height: 18px;
          width: 18px;
        }
      }
    }

    .container {
      background-color: #fff;
      box-sizing: border-box;
      padding: 32px 16px;

      .title {
        font-size: 32px;
        font-style: normal;
        font-weight: bold;
        line-height: 120%;
        margin: 0 0 8px;
        text-align: center;
      }

      .description {
        font-size: 20px;
        font-style: normal;
        line-height: 160%;
        margin: 0 0 40px;
        text-align: center;
      }
    }
  }

  @media screen and (min-width: $md) {
    .podcast-content-form-modal {
      .modal-header {
        height: 64px;

        .btn-exit {
          svg {
            height: 32px;
            width: 32px;
          }
        }
      }

      .container {
        border-radius: 8px;
        max-width: 500px;
        padding: 64px;

        .description {
          margin-bottom: 36px;
        }

        .btn-inside-input {
          margin-left: auto;
          margin-right: auto;
          max-width: 400px;
        }
      }
    }
  }

  @media screen and (min-width: $lg) {
    .podcast-content-form-modal {
      .container {
        max-width: 650px;

        .btn-inside-input {
          max-width: 468px;
        }
      }
    }
  }
}
