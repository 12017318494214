/**
 * InputListCard Styles.
 * Radio override styles inspired by: https://moderncss.dev/pure-css-custom-styled-radio-buttons/.
 */
.multi-step-form {
  .form-control {
    border: 1px solid var(--gray-30);
    border-radius: var(--spacing-tighter);
    box-sizing: border-box;
    cursor: pointer;
    display: grid;
    gap: var(--spacing-tighter);
    grid-template-columns: var(--spacing-normal) auto;
    margin: var(--spacing-tighter) auto;
    padding: 0 !important;
    width: 100%;

    // Ensure all children inherit cursor pointer;
    * {
      cursor: inherit;
    }

    &:focus-within {
      color: var(--form-control-color);
    }

    .label {
      display: flex;
      flex-direction: row;
      padding: var(--spacing-normal) !important;
      width: 100%;
    }

    .radio {
      border: 1px solid var(--black);
      border-radius: 100%;
      box-sizing: border-box;
      flex-shrink: 0;
      height: 20px;
      margin: auto var(--spacing-tighter);
      order: 1;
      width: 20px;

      &:checked {
        background-color: var(--card-link-20);
        border-width: 2px;
        border-color: var(--card-link);
        box-shadow: 0 0 0 2px var(--card-link);
        outline: none;

        &::before {
          transform: scale(1);
        }
      }

      &:focus {
        border-width: 2px;
        border-color: var(--card-link);
        box-shadow: 0 0 0 2px var(--card-link);
        outline: none;
      }

      input[type='radio'] {
        -webkit-appearance: none;
        appearance: none;
        background-color: var(--card-link);
        border: 1px solid var(--black);
        border-radius: 50%;
        color: currentColor;
        display: grid;
        font: inherit;
        height: 20px;
        margin: 0;
        place-content: center;
        transform: translateY(1.2px);
        width: 20px;

        &::before {
          background-color: CanvasText;
          border-radius: 50%;
          box-shadow: inset 12px 12px var(--card-link);
          content: '';
          height: 10px;
          transform: scale(0);
          transition: 120ms transform ease-in-out;
          width: 10px;
        }
      }
    }

    .label-text {
      color: var(--black);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 31px;
    }

    .description-text {
      color: var(--gray);
      max-width: max-content;
    }

    .display-none {
      display: none !important;
    }

    .text-container {
      flex-grow: 1;
    }
  }
}
