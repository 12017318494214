@import '../../../styles/_variables.scss';

.podcast-filter {
  margin-bottom: var(--spacing-bit_more_relaxed);

  .content {
    margin: auto;

    h1,
    p {
      margin: 0;
    }

    > h1 {
      font-size: var(--f-subhead);
      font-weight: var(--fw-black);
      line-height: var(--line-height-tighter);
      margin-bottom: var(--spacing-normal);
    }

    > p {
      font-size: var(--f-feature_paragraph);
      font-weight: var(--fw-regular);
      line-height: var(--line-height-tight);
      margin-bottom: var(--spacing-more_relaxed);
    }
  }
}

@media screen and (min-width: $md) {
  .podcast-filter {
    margin-bottom: var(--spacing-very_relaxed);

    .content {
      > h1 {
        font-size: var(--f-subhead_desktop);
        margin-bottom: var(--spacing-relaxed);
      }

      > p {
        margin-bottom: var(--spacing-even_more_relaxed);
      }

      .filter-buttons {
        flex-direction: row;
      }
    }
  }
}

@media screen and (min-width: $lg) {
  .podcast-filter {
    .content {
      > h1 {
        font-size: var(--f-section_header_desktop);
      }
    }
  }
}
