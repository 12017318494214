@import '../../../styles/_variables.scss';
.media-detail-series-based {
  .ns-form-modal {
    .modal {
      margin-left: 16px;
      margin-right: 16px;
      width: calc(100% - 32px);

      @media screen and (min-width: $md) {
        margin-left: auto;
        margin-right: auto;
        margin-top: 48px;
        max-height: calc(100vh - 128px) !important;
      }
    }
  }

  .modal {
    margin: auto;
    margin-top: 48px;
    max-width: 768px;
    width: calc(100% - 32px);

    @media screen and (min-width: $md) {
      margin-top: 96px;
      width: fit-content;
    }

    .modal-title {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 0px;
      text-align: center;
      width: 100%;
    }

    .has-spacing {
      box-sizing: border-box;
      padding: 16px;

      @media screen and (min-width: $md) {
        padding: 33px;
      }

      @media screen and (min-width: $lg) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 768px;
        width: 768px;
      }
    }

    .embed-item {
      @media screen and (min-width: $md) {
        width: 640px;
      }
    }
  }

  .form-nextstep {
    align-items: center;
    border-radius: 8px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
    display: flex;
    font-size: 16px;
    justify-content: flex-start;
    margin-bottom: 16px;
    margin-left: 0;
    margin-right: 0;
    max-width: unset;
    min-height: 78px;
    padding: 16px;
    text-align: left;
    width: 100%;

    &:last-of-type {
      margin-bottom: 0;
    }

    &.ns-btn {
      display: inline-flex;
      margin: 8px;
      max-width: 272px;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      .modal-icon {
        height: 32px;
        width: 32px;
      }

      @media screen and (min-width: $md) and (max-width: $lg) {
        width: auto;
      }
    }

    .modal-icon {
      height: 40px;
      margin-right: 16px;
      width: 40px;
    }

    .ns-btn-label {
      display: flex;
      flex-direction: column;
      gap: 0;
    }

    .ns-btn-title {
      display: flex;
      font-size: var(--f-normal);
      font-weight: var(--fw-semi_bold);
      margin-bottom: 0;
    }

    .ns-btn-subtitle {
      display: flex;
      font-size: var(--f-xsmall);
      margin-bottom: 0;
    }

    @media screen and (min-width: $md) {
      max-width: 343px;
    }
  }

  .next-steps-carousel {
    display: block;
    margin-bottom: var(--spacing-normal);
    margin-top: -8px; // Top margin of contained buttons.
    -ms-overflow-style: none;
    overflow-x: auto;
    scrollbar-width: none;
    white-space: nowrap;

    &::webkit-scrollbar {
      display: none;
    }

    @media screen and (min-width: $md) {
      margin-bottom: var(--spacing-bit_more_relaxed);
      margin-top: 0;
    }
  }
}
