@import '../../styles/_variables.scss';

.multi-step-form {
  height: -webkit-fill-available;
  min-height: -webkit-fill-available;

  h1 {
    font-family: 'Proxima Nova', var(--font-family);
    text-align: center;
  }

  h2 {
    font-family: 'Proxima Nova', var(--font-family);
    text-align: left;

    &.align-center {
      text-align: center;
    }
  }

  .container {
    height: -webkit-fill-available;
    max-width: 100%;
    padding: 0 1rem 0 1rem;
  }

  div.error,
  .error {
    color: var(--red_again);
    font-family: 'Proxima Nova', var(--font-family);
    font-size: var(--f-xsmall);
    margin-bottom: 0.75rem;
    text-align: left;

    &.text-center {
      text-align: center;
    }
  }

  div.error {
    width: 100%;
  }

  .full-screen {
    height: 100vh;
  }

  input::-webkit-date-and-time-value {
    text-align: left;
  }

  .input {
    input {
      cursor: text;

      &:focus {
        outline: none;
      }

      &::-webkit-calendar-picker-indicator {
        cursor: pointer;
      }
    }

    input[type='date'] {
      background-color: white;
      display: block;
      min-height: 60px;
      min-width: -webkit-fill-available;
      width: -webkit-fill-available;

      &:-webkit-date-and-time-value {
        text-align: left;
      }

      &::-webkit-inner-spin-button {
        display: none;
      }

      &::-webkit-calendar-picker-indicator {
        display: none;
      }

      &::-webkit-input-placeholder {
        visibility: hidden !important;
      }
    }

    &.input-error {
      border-color: var(--red_again);

      input {
        border-color: var(--red_again);
      }
    }
  }

  .header {
    position: relative;

    h1 {
      margin-top: var(--spacing-normal);
    }
  }

  .section {
    align-items: center;
    max-height: 100vh;
    min-height: 100vh;
    text-align: center;
  }

  .inner-section {
    flex: 1;
    max-width: 600px;
    overflow: auto;
    padding-bottom: var(--spacing-normal);
    padding-top: 1rem;
    width: 100%;
  }

  .back-button {
    left: calc(var(--spacing-even_tighter) * - 1);
    margin: 0;
    margin-top: calc(var(--spacing-tighter) * -1);
    position: absolute;
    top: 50%;
  }

  .button-wrapper {
    background: var(--white);
    box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.15);
    margin-left: -1rem;
    margin-top: 0 !important;
    width: 100%;
  }

  .separator-light {
    border: none;
    border-top: 1px solid var(--gray-10);
    width: 100%;
  }

  .confirmation-animation-wrapper {
    height: 72px;
    width: 72px;
  }

  .loader {
    &.circular {
      animation: circularRotation 0.8s linear infinite;
      border: 2px solid #fff;
      border-bottom-color: transparent;
      border-radius: 50%;
      box-sizing: border-box;
      display: inline-block;
      height: 17px;
      width: 17px;
    }

    &.gray100 {
      border-color: var(--gray);
      border-bottom-color: transparent;
    }
  }

  @keyframes circularRotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @media only screen and (max-width: $md) {
    .section {
      // Mobile viewport bug fix.
      max-height: -webkit-fill-available;
      min-height: -webkit-fill-available;
    }
  }
}
