@import '../../styles/_variables.scss';

.footer-wrapper {
  padding: 2rem 0;
  text-align: center;

  &.container {
    & > .rich-text.container {
      max-width: unset;
      padding: 0;
    }
  }

  .left-content {
    text-align: center;

    p {
      color: #404041;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      line-height: 150%;
      letter-spacing: -0.13px;
      margin-bottom: var(--spacing-normal);

      a {
        color: #1ba9d5;
      }
    }
  }

  .page-nav-links,
  .social-media-links {
    display: flex;
    justify-content: center;
  }

  .page-nav-links {
    display: none;
  }

  .social-media-links {
    padding-top: 6px;

    .icon {
      font-size: 2rem;
    }

    .social-link {
      margin-right: 14px;
      text-decoration: none;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.footer-bottom-wrapper {
  margin-top: -1rem;
  padding: 0 0 2rem 0;
  text-align: center;

  .bottom-content {
    p {
      color: #404041;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      line-height: 150%;
      letter-spacing: -0.13px;
      margin-bottom: var(--spacing-normal);

      a {
        color: #1ba9d5;
      }
    }
  }
}

@media screen and (min-width: $md) {
  .footer-wrapper {
    .left-content {
      p {
        margin-bottom: 20px;
      }
    }

    .page-nav-links {
      display: flex;
      flex-wrap: wrap;
      margin-top: 8px;
      padding: 0 16px;

      .anchor-link {
        color: #1ba9d5;
        margin-right: 16px;
        margin-bottom: 16px;
        min-height: unset;

        &:last-of-type {
          margin-right: 0;
        }
      }

      .link-text {
        font-size: var(--f-small);
        font-weight: normal;
        white-space: nowrap;
      }
    }
  }

  .footer-bottom-wrapper {
    .bottom-content {
      p {
        margin-top: 0;
      }
    }
  }
}

@media screen and (min-width: $lg) {
  .footer-wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    text-align: left;

    .page-nav-links {
      margin-top: 0;
      padding: 0;
    }

    .left-content {
      max-width: 100%;
      padding: 0;
      text-align: left;
      width: 100%;

      p:last-of-type {
        margin-bottom: 0;
      }
    }

    .right-content {
      .page-nav-links,
      .social-media-links {
        justify-content: flex-end;
      }
    }
  }

  .footer-bottom-wrapper {
    margin-top: -1.5rem;

    .bottom-content {
      max-width: 100%;
      padding: 0;
      text-align: center;
      width: 100%;

      p:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
