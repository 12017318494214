@import '../../../styles/_variables.scss';

.get-update {
  padding: 64px 0;

  .container {
    text-align: center;

    .btn-inside-input {
      .hs-email.hs-fieldtype-text.field.hs-form-field {
        margin-bottom: 40px;
      }

      .hs-submit {
        bottom: 4px;
        top: unset;
      }

      .hs-richtext {
        h3 {
          font-size: 32px;
          margin-bottom: 16px;
        }

        p {
          font-size: 20px;
          margin-bottom: 32px;
        }
      }

      .hs-email .hs-error-msgs,
      .hs_error_rollup {
        left: calc(50% - 118px);
        position: absolute;

        ul {
          left: calc(50% - 163px);
        }
      }

      .btn {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (min-width: $md) {
  .get-update {
    .container {
      .btn-inside-input {
        .hs-richtext {
          h3 {
            font-size: 40px;
          }

          p {
            font-size: 24px;
          }
        }

        .hs-submit,
        .hs-email {
          margin: 0 auto;
          max-width: 415px;
        }

        .hs-submit {
          right: calc(50% - 202px);
        }
      }
    }
  }
}
