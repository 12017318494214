// Global callback styles.
.callback {
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 20px 22px 32px;
  position: relative;
  right: 0;
  text-align: center;
  top: 0;

  &.flex-center {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 100vh;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
  }

  img {
    height: auto;
    width: 100px;

    &.lc-logo {
      width: 250px;
    }
  }
}

@media screen and (max-width: $md) {
  .callback {
    // Mobile viewport bug fix.
    max-height: -webkit-fill-available;
    min-height: -webkit-fill-available;
  }
}
@media screen and (min-width: $md) {
  .callback {
    height: 572px !important;
    max-width: 500px;
    object-fit: contain;
    padding: 34px 55px 24px;

    &.flex-center {
      gap: 40px;
    }
  }
}
