@import '../../styles/_variables.scss';

@keyframes live {
  0% {
    color: #9f9fa0;
  }

  100% {
    color: #1e1f23;
  }
}

.header {
  .navigation {
    align-items: center;
    display: flex;
    height: 33px;
    justify-content: space-between;
    padding: var(--spacing-relaxed) 16px;

    .nav-desktop {
      display: none;
    }

    .menu-live {
      animation: 1s linear 0s infinite forwards;

      a {
        animation: 1s linear 0s infinite forwards;
      }
    }

    .logo {
      margin: 0.5rem var(--spacing-normal) 0 0;
      max-width: 185px;
    }

    .menu-nav {
      align-items: center;
      display: flex;
      text-transform: uppercase;

      button {
        text-transform: uppercase;
      }

      .login,
      .hamburger-menu {
        align-items: center;
        color: #444;
        display: flex;
        text-decoration: none;
        white-space: nowrap;

        > span {
          color: #404041;
          display: none;
          font-weight: 600;
        }

        &:hover {
          > span {
            color: var(--card-link);
          }
        }
      }

      .login {
        display: flex;
        gap: var(--spacing-normal);

        > img {
          border-radius: 100%;
          height: 1.5rem;
          object-fit: cover;
          width: 1.5rem;
        }

        .login-text {
          &.no-username {
            display: none;
          }
        }
      }

      .hamburger-menu {
        position: relative;

        > .hamburger {
          display: flex;
          flex-direction: column;
          margin-right: 3px;
          min-height: 10px;
          min-width: 15px;
          position: relative;

          span {
            background-color: #444;
            height: 2px;
            position: absolute;
            top: 0;
            transition: transform 0.4s, top 0.2s ease-in;
            width: 15px;
          }

          span:nth-child(2) {
            top: 4px;
          }

          span:nth-child(3) {
            top: 8px;
          }

          &.active {
            span:nth-child(1),
            span:nth-child(2) {
              top: 5px;
              transform: rotate(45deg);
            }

            span:nth-child(3) {
              top: 5px;
              transform: rotate(-45deg);
            }
          }
        }

        > span {
          margin-left: 6px;
        }
      }

      #login-wrapper {
        margin-right: var(--spacing-kinda_relax);
        position: relative;

        .profile-menu {
          background: #fff;
          border-radius: 0.375rem;
          bottom: 0;
          box-shadow: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.25);
          display: none;
          font-size: 14px;
          font-weight: bold;
          left: -4.5rem;
          opacity: 0;
          position: absolute;
          transform: translateY(calc(100% + 15px));
          transition: opacity 0.2s ease-in-out;
          width: 125px;
          z-index: 10000;

          .menu-item {
            border-bottom: 0.0625rem solid #ececec;

            &:last-child {
              border-bottom: none;
            }

            .menu-item-link {
              color: #9f9fa0;
              display: block;
              margin-left: 1rem;
              padding: 0.75rem 0 0.5rem;
              text-decoration: none !important;
              text-transform: uppercase;

              &:hover {
                color: #0b83a6;
              }

              &.logout {
                color: #e33300;
              }
            }
          }

          &:before {
            content: '';
            background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNiAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04IDBMMTYgOC43MTRIMEw4IDBaIiBmaWxsPSIjRUNFQ0VDIi8%2BCjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNOCAxTDE2IDkuNzE0SDBMOCAxWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc%2BCg%3D%3D)
              no-repeat;
            background-position: 70%;
            background-size: 1.25rem 0.75rem;
            display: block;
            height: 2rem;
            position: absolute;
            top: -1.3125rem;
            width: 100%;
          }
        }

        &.logged-in {
          &:hover {
            .profile-menu {
              display: block;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: $md) {
  .header {
    .navigation {
      .menu-nav {
        .login,
        .hamburger-menu {
          > span {
            display: unset;
            font-size: var(--f-small);
            font-family: var(--font-family);
          }
        }

        .hamburger-menu {
          > img {
            margin-right: var(--spacing-normal);
          }
        }

        #login-wrapper {
          .profile-menu {
            left: calc(50% - 4.688rem);
            width: 9.375rem;

            &:before {
              background-position: center;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: $lg) {
  .header {
    .navigation {
      .nav-desktop {
        display: unset;
        position: relative;

        &.live-now {
          animation: change 2s step-end infinite;

          @keyframes change {
            0% {
              color: inherit;
            }
            50% {
              color: #404041;
            }
            75% {
              color: #404041;
            }
          }
        }

        .live-status {
          bottom: -17px;
          font-size: 9px;
          font-weight: 600;
          left: 0;
          position: absolute;
        }
      }

      .main-nav {
        align-items: center;
        display: flex;

        &.active {
          > li {
            color: #444;

            a {
              color: #444;
            }
          }
        }

        > li {
          color: #9f9fa0;
          margin-right: var(--spacing-normal);

          &:hover {
            color: var(--social_blues);

            a {
              color: var(--social_blues);
            }
          }

          a {
            color: #9f9fa0;
            font-size: var(--f-small);
            font-weight: 600;
            line-height: 24px;
            text-decoration: none;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

@keyframes churchonlinelive {
  0% {
    color: #9f9fa0;
  }

  100% {
    color: black;
  }
}
