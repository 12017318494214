@import '../../styles/_variables.scss';

.special-event-times {
  color: var(--black);
  margin: 0 auto;
  max-width: 568px;

  @media screen and (max-width: $md) {
    padding: 0 16px;

    .btn {
      width: calc(100% - 32px);
    }
  }

  .location-select {
    appearance: none;
    background: #ffffff;
    background-image: url('../../assets/angle-down.svg');
    background-position-x: calc(100% - 24px);
    background-position-y: calc(100% - 18px);
    background-repeat: no-repeat;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(45, 45, 45, 0.25);
    font-size: 24px;
    font-weight: 400;
    height: 56px;
    line-height: 160%;
    margin-bottom: 24px;
    padding: 0px 24px;
    width: 100%;
  }

  .smart-location {
    margin-bottom: 16px;
  }

  .location-loading {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: center;

    p {
      line-height: 24px;
      margin: 0;
    }
  }

  .find-location-near-me {
    padding: 0;
    text-decoration: underline !important;

    &:hover {
      color: var(--black);
    }
  }

  .location-loading,
  .find-location-near-me {
    .link-text {
      font-size: 20px;
      font-weight: 600;
    }
  }

  .location-found {
    align-items: center;
    display: flex;
    font-size: 20px;
    font-weight: 600;
    gap: 8px;
    justify-content: center;

    p {
      margin: 0;
    }
  }

  .no-location-found {
    align-items: center;
    display: flex;
    gap: 12px;
    justify-content: center;

    a,
    p {
      color: var(--black);
      font-size: 20px;
      font-weight: 600;
      margin: 0;
    }

    a {
      text-decoration: underline;
    }

    svg {
      path {
        fill: var(--black);
      }
    }
  }

  .special-events {
    background-color: var(--white);
    border-radius: 8px;
    box-shadow: 0px 2px 12px rgba(45, 45, 45, 0.25);

    .content-wrapper {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-tighter);
      overflow-y: scroll;
      padding: 0.5rem 1rem;
    }

    .special-events-wrapper {
      background: linear-gradient(
        0deg,
        #ffffff 100%,
        rgba(255, 255, 255, 0) 0%
      );
      max-height: 400px;
      padding: 0.5rem 1rem;
    }

    .event-location-name-heading {
      border-bottom: 2px solid var(--lighter-gray);
      margin-bottom: 0;
      margin-top: var(--spacing-tighter);
      padding-bottom: var(--spacing-even_tighter);
    }

    .special-events-item,
    .location-data-item {
      border-bottom: 2px solid var(--lighter-gray);

      .date,
      .time {
        font-size: 20px;
      }

      .date {
        color: var(--gray);
      }

      .location-data {
        gap: var(--spacing-normal);

        span[class^='icon-'] {
          height: 1.5rem;
          width: 1.5rem;
        }
      }

      .address,
      .phone {
        p {
          margin-top: 0;
        }
      }

      .event-subcontainer {
        max-height: 300px;
        overflow-y: scroll;
        padding-right: 2rem;
      }

      .poster-profile {
        border-radius: 100%;
        height: 80px;
        width: 80px;
      }

      .poster-name {
        h2 {
          color: var(--black);
          font-size: 20px;
          margin: 0;
        }
      }

      .poster-event {
        &-times {
          display: flex;
          flex-wrap: wrap;
          gap: var(--spacing-tighter);
          justify-content: flex-start;
        }

        &-time {
          background-color: #f2f2f2;
          border-radius: 2px;
          color: #3d3d40;
          font-size: var(--f-normal);
          font-weight: 400;
          padding: 6px 8px;
        }
      }
    }

    .btn {
      display: inline-block;
    }

    .grad {
      background: linear-gradient(
        0deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
      height: 20px;
      margin-bottom: -5px;
      margin-top: -20px;
      position: relative;
      z-index: 2;
    }
  }

  @media screen and (min-width: $md) {
    .smart-location {
      margin-bottom: 24px;
    }
  }
}

.text-white {
  .smart-location {
    a,
    p {
      color: var(--white);

      &:hover {
        color: var(--white);
      }
    }
  }

  .no-location-found {
    svg {
      path {
        fill: var(--white);
      }
    }
  }

  .location-loading {
    color: var(--white);
  }
}

.text-black {
  .smart-location {
    a,
    p {
      color: var(--black);

      &:hover {
        color: var(--black);
      }
    }
  }

  .location-loading {
    color: var(--black);
  }
}
