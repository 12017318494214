@import '../../../styles/_variables.scss';

.collection-album-hero-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
  position: relative;
  text-align: center;
  width: auto;

  > * {
    position: relative;
  }

  .album-title {
    font-size: 40px;
    font-weight: 900;
    line-height: 40px;
    margin-bottom: 16px;
    margin-top: 0;
  }

  .album-image {
    padding-bottom: 100%;
    width: 100%;
  }

  .button-group-wrapper {
    margin-bottom: 0;
    margin-top: 32px;

    .btn-channel {
      margin: 8px;
    }
  }
}

@media screen and (min-width: $md) {
  .collection-album-hero-container {
    flex-direction: row;
    padding: 48px 16px;

    .album-title {
      font-size: 64px;
      line-height: 64px;
      margin-bottom: 24px;
    }

    .album-image {
      margin-right: 32px;
      margin-top: 0;
      padding-bottom: calc(50% - 32px);
      width: calc(50% - 32px);
    }

    .album-image,
    .button-group-wrapper {
      flex: 50%;
      margin-top: 0;

      .channel-buttons-group {
        margin: -8px;

        .btn-channel {
          margin: 8px;

          .icon {
            background-size: contain;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $lg) {
  .collection-album-hero-container {
    padding: 98px 16px;

    .album-title {
      margin-bottom: 32px;
    }

    .album-image {
      max-width: 468px;
      padding-bottom: 468px;
    }

    .button-group-wrapper {
      max-width: 568px;
    }
  }
}
