@import '../../../styles/_variables.scss';

.podcast-accordion {
  .title {
    color: #2d2d2e;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: 39px;
    margin: 0 0 32px;
    text-align: center;
  }
}

@media screen and (min-width: $md) {
  .podcast-accordion {
    .title {
      font-size: 32px;
      line-height: 120%;
      margin-bottom: 16px;
    }

    .accordion {
      @media screen and (max-width: $lg) {
        margin: 0 auto;
        max-width: 100% !important;
        width: 66%;
      }
    }
  }
}

@media screen and (min-width: $lg) {
  .podcast-accordion {
    .title {
      font-size: 40px;
    }

    .accordion-listing {
      width: 100%;
    }
  }
}
