@import "../../styles/_variables.scss";

.location-detail-prelaunched {
  &.paster-detail-and-map {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0;
    max-width: 100%;

    .pastor-detail-wrapper {
      padding: 48px 0;
      text-align: center;
      width: 100%;

      .contact-service {
        max-width: 355px;

        .service-address {
          font-size: 20px;
          line-height: 31px;
          margin-bottom: 0;
        }

        .service-title {
          font-size: 24.96px;
          font-weight: var(--fw-normal-bold);
          line-height: 31px;
          margin: 0 auto var(--spacing-tighter);
        }
      }

      .profile-wrapper {
        align-items: center;
        column-gap: 24px;
        display: flex;
        justify-content: center;
        max-width: 355px;

        .profile-image {
          border-radius: 100%;
          height: 96px;
          width: 96px;
        }

        .profile-detail {
          text-align: left;

          .profile-name {
            font-size: 31px;
            line-height: 39px;
            margin: 0;
          }

          .profile-role {
            color: #666667;
            font-size: 20px;
            margin: 0;
          }
        }
      }
    }

    .map-wrapper {
      height: 490px;
      position: relative;
      width: 100%;

      #map {
        height: 100%;
        width: 100%;

        .mapboxgl-canvas {
          height: 100% !important;
        }
      }

      .location-pin {
        background-image: url("data:image/svg+xml,<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='map-marker-alt' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512' class='svg-inline--fa fa-map-marker-alt fa-w-12 fa-3x'><path fill='currentColor' d='M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z'></path></svg>");
        background-repeat: no-repeat;
        background-size: 30px;
        height: 60px;
        width: 35px;
      }

      .get-direction-wrapper {
        align-items: center;
        background-color: transparent;
        display: flex;
        height: 100%;
        justify-content: center;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: all 0.5s;
        visibility: hidden;
        width: 100%;

        &.hovered {
          background-color: rgba(0, 158, 204, 0.8);
          opacity: 1;
          visibility: visible;

          .btn-with-map-icon {
            &:hover {
              background-color: var(--blue-bg-light);
              box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
              color: #00b3e5;
              text-decoration: none;
            }
          }
        }

        .btn-with-map-icon {
          align-items: center;
          border: 0.625px solid #fff;
          border-radius: 200px;
          box-sizing: border-box;
          color: #fff;
          display: flex;
          font-size: 20px;
          font-weight: 600;
          gap: 16px;
          justify-content: space-between;
          line-height: 20px;
          min-height: 32px;
          padding: 7px 32px;
        }
      }
    }
  }
  @media screen and (min-width: $lg) {
    &.paster-detail-and-map {
      flex-direction: row;
      max-width: 1200px;

      .map-wrapper {
        height: auto;
      }

      .pastor-detail-wrapper,
      .map-wrapper {
        width: 50%;
      }
    }
  }
}
