@import '../../styles/_variables.scss';

$themes: (
  purple: (
    activeTabColor: #291452,
    background: #f8f5f9,
    hoverTabColor: #613181,
    sectionTitle: #402061,
    tabColor: #1c2023,
    underlineColor: #170f3d,
  ),
  green: (
    activeTabColor: #01ad8f,
    background: #effbf9,
    hoverTabColor: #23e0bf,
    sectionTitle: #09c1a1,
    tabColor: #1c2023,
    underlineColor: #1b7768,
  ),
  blue: (
    activeTabColor: #1657a7,
    background: #f6fafe,
    hoverTabColor: #1d8ee4,
    sectionTitle: #1c70c9,
    tabColor: #1c2023,
    underlineColor: #103870,
  ),
  teal: (
    activeTabColor: #016b7d,
    background: #f4f9fa,
    hoverTabColor: #198598,
    sectionTitle: #00788c,
    tabColor: #1c2023,
    underlineColor: #003c46,
  ),
  gray: (
    activeTabColor: #1c2023,
    background: #f6f6f6,
    hoverTabColor: #848b90,
    sectionTitle: #1c2023,
    tabColor: #1c2023,
    underlineColor: #1c2023,
  ),
);

.navigation-bar {
  @each $theme, $attributes in $themes {
    &.theme-#{$theme} {
      background: map-get($attributes, 'background');
      border-top: 0.125rem solid map-get($attributes, 'sectionTitle');

      .title {
        color: map-get($attributes, 'sectionTitle');
      }

      .container .navigation ul {
        background: map-get($attributes, 'background');

        li a {
          color: map-get($attributes, 'tabColor');

          &.active,
          &:hover {
            border-bottom-color: map-get($attributes, 'underlineColor');
            border-left-color: map-get($attributes, 'activeTabColor');
            color: map-get($attributes, 'activeTabColor');
            text-decoration: none;
          }

          &:hover {
            border-bottom-color: map-get($attributes, 'hoverTabColor');
            border-left-color: map-get($attributes, 'hoverTabColor');
            color: map-get($attributes, 'hoverTabColor');
          }
        }
      }
    }
  }

  .container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 16px;

    .title {
      font-size: 1.5rem;
      font-weight: var(--fw-semi_bold);
      margin: 0;
    }

    .navigation {
      position: relative;

      .mobile-nav-selected {
        display: block;
        font-size: 1.375rem;
        padding: var(--spacing-slight_relax);
        padding-right: var(--spacing-bit_more_relaxed);

        &::after {
          border: inset 6px;
          border-bottom-width: 0;
          border-color: #000 transparent transparent;
          border-top-style: solid;
          content: '';
          display: block;
          height: 0;
          left: auto;
          position: absolute;
          right: 5px;
          top: 50%;
          width: 0;
        }
      }

      ul {
        display: none;
        flex-direction: column;
        min-width: 200px;
        position: absolute;
        right: -16px;
        top: calc(36px + 1rem); // Line height plus padding.

        &.active {
          display: block;
          z-index: 100;
        }

        li {
          &:last-child {
            margin-bottom: 2px;
          }

          a {
            border-left: 0.1875rem solid transparent;
            color: var(--gray);
            display: block;
            font-size: 1.375rem;
            padding: var(--spacing-slight_relax);
            text-align: center;
            white-space: nowrap;
          }
        }
      }

      ul.worship li a {
        color: var(--gray-50);
      }
    }
  }

  @media only screen and (min-width: $md) {
    .container {
      padding: 0 16px;

      .navigation {
        .mobile-nav-selected {
          display: none;
        }

        ul {
          display: flex;
          flex-direction: row;
          min-width: unset;
          position: relative;
          top: 0;

          li {
            margin-bottom: 0px;

            &:last-child {
              margin-bottom: 0px;
            }

            a {
              border-bottom: 0.1875rem solid transparent;
              border-left: 0;
              font-size: 1rem;
              text-align: left;
            }
          }
        }
      }
    }
  }
}
