@import '../../../styles/_variables.scss';

.podcast-collection-hero {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: flex-end;
  position: relative;

  .podcast-hero-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    .container {
      width: calc(100% - 32px);
    }

    .title {
      font-size: 40px;
      line-height: 100%;
      margin: 0 0 6px;
    }

    .description {
      font-size: 20px;
      line-height: 160%;
      margin: 0 0 24px;
    }

    .podcast-btn-hero {
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 64px;
      box-sizing: border-box;
      font-size: 16px;
      font-weight: 600;
      line-height: 100%;
      margin-bottom: 24px;
      padding: 12px 32px;
    }
  }
}

@media screen and (min-width: $md) {
  .podcast-collection-hero {
    justify-content: center;

    .podcast-hero-content {
      box-sizing: border-box;
      justify-content: center;
      text-align: left;
      width: 100%;

      .title {
        width: 50%;
      }

      .description {
        margin-bottom: 12px;
        width: 50%;
      }

      .podcast-btn-hero {
        margin: 0;
      }
    }
  }
}

@media screen and (min-width: $lg) {
  .podcast-collection-hero {
    .podcast-hero-content {
      .title {
        font-size: 64px;
      }
    }
  }
}
