.multi-step-form {
  .form-control {
    .icon-chip {
      background: var(--blue-bg-light);
      border-radius: var(--spacing-even_tighter);
      display: inline-flex !important;
      height: auto;
      margin: 0 auto var(--spacing-even_tighter) auto;
      padding: var(--spacing-even_tighter) var(--spacing-tighter);
      width: auto;

      .icon {
        margin-right: var(--spacing-even_tighter);
      }

      .icon-text {
        color: var(--card-link);
        font-weight: 700;
        justify-content: center;
      }
    }
  }
}
