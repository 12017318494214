@import '../../styles/_variables.scss';

.lc-dev-bar {
  background: rgba(0, 0, 0, 0.125);
  box-shadow: inset 0px 4px 4px -3px rgba(0, 0, 0, 0.25);
  color: var(--gray);
  max-width: calc(100% - var(--spacing-normal) - var(--spacing-normal));
  padding: var(--spacing-normal) var(--spacing-normal);
  position: relative;
  text-align: center;
  transition: all 0.3s ease-out;

  h3 {
    color: var(--gray);
    font-size: var(--f-small);
    font-weight: var(--fw-bold);
    margin: 0 0 var(--spacing-tighter);
  }

  ul,
  li,
  p {
    font-size: var(--f-small);
    line-height: var(--spacing-normal);
  }

  small {
    line-height: var(--spacing-normal);
    margin-top: var(--spacing-tighter);
  }

  button.btn-expand {
    display: none;
    font-weight: var(--fw-bold);
    margin-left: calc(var(--spacing-normal) * -1);
    padding: var(--spacing-normal);
    position: relative;
    right: 0;
    top: 0;

    .label {
      display: none;
    }
  }

  div.info-tooltip {
    background: var(--gray);
    border-radius: 50%;
    color: var(--white);
    cursor: pointer;
    display: inline-block;
    font-size: var(--f-xsmall);
    font-weight: var(--fw-bold);
    height: var(--spacing-tight);
    line-height: 0.875;
    margin: 0 var(--spacing-even_tighter);
    padding: var(--spacing-even_tighter);
    position: relative;
    width: var(--spacing-tight);

    .tooltip-text {
      background-color: rgba(0, 0, 0, 0.875);
      bottom: var(--spacing-relaxed);
      color: var(--white);
      left: -160px;
      line-height: 120%;
      padding: var(--spacing-tighter);
      position: absolute;
      text-align: center;
      visibility: hidden;
      width: 320px;
      z-index: 999999;

      @media screen and (max-width: $md) {
        left: -200px;
        max-width: 240px;
      }
    }

    &.expanded {
      .tooltip-text {
        visibility: visible;
      }
    }
  }

  .border-bottom {
    border-bottom: 1px dotted rgba(0, 0, 0, 0.35);
  }

  .border-top {
    border-top: 1px dotted rgba(0, 0, 0, 0.35);
  }

  .disclaimer {
    padding-top: var(--spacing-tighter);
  }

  .content {
    height: auto;
    width: auto;

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    li {
      border-right: 1px dotted rgba(0, 0, 0, 0.35);
      margin-bottom: var(--spacing-tighter);
      padding-left: var(--spacing-slight_tight);
      padding-right: var(--spacing-slight_tight);

      &:last-of-type {
        border-left: none;
        border-right: none;
      }
    }
    @media screen and (max-width: $md) {
      ul {
        flex-direction: column;
      }

      li {
        border: none;
      }
    }
  }
}
