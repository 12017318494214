@import '../../styles/variables';

.about-life-church {
  background: var(--lighter-gray);
  max-width: calc(100% - 32px);
  padding: 24px 16px;

  .contents {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: $contentMaxWidth;
    text-align: center;
  }

  @media screen and (min-width: $lg) {
    padding: 32px 16px;

    .contents {
      width: calc(100% * (2 / 3));
    }
  }
}
