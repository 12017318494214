.input {
  display: flex;
  flex-direction: column;
  text-align: left;

  label {
    color: var(--grayscale);
    font-size: var(--f-paragraph_large);
    font-weight: bold;
    line-height: var(--line-height-normal);
  }

  input,
  select {
    border: 1px solid #c6c6c6;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: var(--f-paragraph_large);
    padding: 15px;
  }

  span {
    color: var(--grayscale);
    margin-top: auto;
  }

  p.error {
    color: var(--red_again);
    font-family: 'Proxima Nova', var(--font-family);
    font-size: var(--f-xsmall);
    margin-top: 8px;
    text-align: left;
    width: 100%;
  }
}
