@import '../../../styles/_variables.scss';

.podcast-quote {
  background-color: #f6f6f6;
  padding-bottom: 48px;
  padding-top: 48px;

  .title {
    color: #2d2d2e;
    font-size: 24px;
    font-style: normal;
    font-weight: bold;
    line-height: 120%;
    margin: 0 0 32px;
    text-align: center;

    @media screen and (min-width: $lg) {
      font-size: 32px;
      line-height: 120%;
    }
  }

  .podcast-quote-slide {
    .slider-control-centerleft {
      left: 16px !important;
    }

    .slider-control-centerright {
      right: 16px !important;
    }

    .slider-control-bottomcenter {
      display: none;
    }

    .custom-prev-button,
    .custom-next-button {
      background-color: white !important;
    }

    .no-controls {
      .custom-prev-button,
      .custom-next-button {
        display: none;
      }
    }

    .slider {
      margin: auto;
      max-width: 100%;

      .custom-prev-button,
      .custom-next-button {
        border-radius: 50%;
        color: white;
        height: 44px;
        position: relative;
        width: 44px;

        &::after {
          background-repeat: no-repeat;
          content: '';
          display: block;
          height: 30px;
          left: 16px;
          position: absolute;
          top: 7px;
          width: 15px;
        }
      }

      .custom-prev-button {
        &::after {
          background-image: url("data:image/svg+xml;utf8,<svg aria-hidden='true' focusable='false' data-prefix='far' data-icon='chevron-left' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'><path fill='currentColor' d='M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z'></path></svg>");
          left: 14px;
        }
      }

      .custom-next-button {
        &::after {
          background-image: url("data:image/svg+xml;utf8,<svg aria-hidden='true' focusable='false' data-prefix='far' data-icon='chevron-right' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'><path fill='currentColor' d='M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z'></path></svg>");
        }
      }

      .slider-control-bottomcenter {
        margin: 16px 0 0;
        top: 100%;

        ul {
          column-gap: 10px;
        }
      }

      @media only screen and (max-width: $md) {
        .slider-control-centerleft,
        .slider-control-centerright {
          display: none;
        }
      }
    }

    .quote-item {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 4px;
      height: 187px;

      @media screen and (min-width: $lg) {
        height: 260px;
      }
    }
  }
}
