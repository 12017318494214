@import '../../styles/_variables.scss';

.location-prelaunched-v2 {
  .prelaunched-hero {
    height: calc(100vh - 81px);

    .hero-bg {
      align-items: center;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      height: 100%;
      justify-content: center;
    }

    .hero-content {
      max-width: 450px;
    }

    h1, h2, p {
      margin: 0;
    }

    h1 {
      font-size: 32px;
      font-weight: 900;
      line-height: 120%;
      margin-bottom: 8px;
      white-space: pre-wrap;
    }

    h2 {
      font-size: 24px;
      font-weight: 700;
      line-height: 120%;
      margin-bottom: 40px;
    }

    p {
      font-size: 20px;
      font-weight: 400;
      line-height: 160%;
    }

    .hero-btn {
      display: none;
    }
  }

  .prelaunched-banner {
    align-items: center;
    background-color: #009ecc;
    bottom: 0;
    display: flex;
    justify-content: center;
    padding: 20px 35px;
    position: -webkit-sticky;
    position: sticky;

    .banner-title {
      display: none;
    }

    .banner-btns {
      align-items: center;
      column-gap: 16px;
      display: flex;

      .social-link {
        display: flex;

        &:hover {
          text-decoration: none;
        }

      }

      .icon {
        font-size: 40px;

        .path2 {
          &::before {
            color: #ffffff;
            opacity: 1;
          }
        }

        .path3,
        .path4,
        .path5 {
          &::before {
            color: #009ecc;
            opacity: 1;
          }
        }
      }
    }

    .btn-banner {
      width: 192px;
    }

    @media screen and (min-width: $lg) {
      column-gap: 64px;

      .banner-title {
        display: block;

        > h2 {
          color: #ffffff;
          font-size: 32px;
          font-weight: 700;
          line-height: 120%;
          margin: 0;
        }
      }
    }
  }

  .prelaunched-wte {
    display: flex;
    justify-content: center;
    padding: 48px 0px;


    h2, h3, p {
      margin: 0;
    }

    .wte-header {
      margin-bottom: 24px;
      max-width: 450px;

      h2 {
        font-size: 32px;
        font-weight: 900;
        line-height: 120%;
      }
    }

    .wte-content {
      display: flex;
      flex-direction: column;
      max-width: 450px;
      row-gap: 32px;

      >div:last-of-type {
        display: flex;
        flex-direction: column;
        row-gap: 16px;

        h3 {
          font-size: 24px;
          font-weight: 700;
          line-height: 120%;
        }

        p {
          color: #2d2d2e;
          font-size: 16px;
          font-weight: 400;
          line-height: 160%;
        }
      }

      .wte-btn-group {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
      }
    }
  }

  .prelaunched-download {
    margin-bottom: 48px;

    .container {
      background-image:
        linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url(../LocationV2/assets/SectionDownloadSmall.png);
      background-position-x: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      padding: 72px 16px;
    }

    .download-content {
      align-items: center;
      max-width: 450px;

      img {
        height: 120px;
        width: 120px;
      }

      h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 120%;
      }
    }
  }


  @media screen and (min-width: $md) {
    .prelaunched-hero  {
      height: auto;

      .hero-bg {
        height: 100%;
        padding: 92px 0px;
      }

      .hero-btn {
        display: inline-block;
      }

      h2 {
        margin-bottom: 16px;
      }
    }

    .prelaunched-wte {
      text-align: center;

      .wte-container {
        margin: auto;
        max-width: 450px;
      }

      h2 {
        margin-bottom: 32px;
      }

      .wte-btn-group {
        align-items: center;
      }
    }

    .prelaunched-download {
      .container {
        background-image:
          linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
          url(../LocationV2/assets/SectionDownloadMedium.png);
      }
    }
  }

  @media screen and (min-width: $lg) {
    .prelaunched-hero {
      height: calc(100vh - 81px);

      .hero-bg {
        height: 100%;
        padding: 0;
      }

      .hero-content {
        justify-content: center;
        max-width: 800px;
      }

      h1 {
        font-size: 64px;
        font-weight: 900;
        line-height: 100%;
      }

      h2 {
        font-size: 32px;
        font-weight: 700;
        line-height: 120%;
      }

      p {
        font-size: 24px;
      }

      .hero-btn {
        display: none;
      }
    }

    .prelaunched-wte {
      padding: 112px 0;

      .wte-header,
      .wte-content {
        max-width: 100%;
      }

      .wte-header {
        h2 {
          font-size: 64px;
          margin-bottom: 64px;
        }
      }

      .wte-content {
        align-items: center;
        column-gap: 64px;
        flex-direction: row;
        text-align: left;

        >div {
          flex: 1;
        }

        >div:last-of-type {
          h3, p {
            margin-bottom: 32px;
          }
        }

        .wte-btn-group {
          align-items: flex-start;
          column-gap: 16px;
          flex-direction: row;
        }
      }
    }

    .prelaunched-download {
      margin-bottom: 80px;

      .container {
        background-image:
          linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
          url(../LocationV2/assets/SectionDownloadLarge.png);
        background-position-x: right;
        justify-content: flex-start;
        margin: auto;
        padding: 64px 0px;
      }

      .download-content {
        flex: 1;
        max-width: 552px;
      }

      h2 {
        max-width: 290px;
      }
    }
  }
}