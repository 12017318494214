.mapboxgl-map {
  .font-weight-bold {
    font-family: var(--font-family);
    font-weight: 700;
  }

  .color-black {
    color: #1c2023;
  }

  .mapboxgl-popup-content {
    text-align: center;

    .popup-paragraph {
      font-family: var(--font-family);
      font-size: 1.25rem;
      line-height: 100%;
      margin-bottom: 0.25rem;
      margin-top: 0.25rem;
    }

    .popup-link {
      font-family: var(--font-family);
      font-size: 1rem;
      line-height: 100%;
    }
  }

  .mapboxgl-popup-close-button {
    font-size: var(--spacing-slight_relax);
    padding: 0 6px;
  }
}
