@import '../../styles/_variables.scss';

.open-content-list {
  &.content-vertical-cards,
  &.content-horizontal-cards {
    &.odd {
      &:not(.has-single-item) {
        > div {
          @media only screen and (min-width: $md) {
            grid-template-columns: repeat(
              auto-fill,
              minmax(calc((100% - (var(--spacing-relaxed) * 2)) / 3), 1fr)
            );
          }
        }
      }
      &.has-single-item {
        > div {
          @media only screen and (min-width: $md) {
            grid-template-columns: repeat(
              auto-fill,
              minmax(calc((100% - (var(--spacing-relaxed)))), 1fr)
            );
          }
        }
      }
    }

    &.even {
      &:not(.has-single-item) {
        > div {
          @media only screen and (min-width: $md) {
            grid-template-columns: repeat(
              auto-fill,
              minmax(calc((100% - var(--spacing-relaxed)) / 2), 1fr)
            );
          }
        }
      }
      &.has-single-item {
        > div {
          @media only screen and (min-width: $md) {
            grid-template-columns: repeat(
              auto-fill,
              minmax(calc((100% - var(--spacing-relaxed))), 1fr)
            );
          }
        }
      }
    }

    > div {
      display: grid;
      grid-gap: var(--spacing-relaxed);
    }
  }

  &.content-horizontal-cards {
    > div {
      max-width: 504px;

      @media only screen and (min-width: $md) {
        max-width: unset;
      }
    }
  }

  &.content-media-thumbnail {
    > div {
      display: grid;
      grid-row-gap: var(--spacing-normal);

      @media only screen and (min-width: $md) {
        grid-gap: var(--spacing-normal);
        grid-template-columns: repeat(
          auto-fill,
          minmax(calc((100% - var(--spacing-normal)) / 2), 1fr)
        );
      }

      @media only screen and (min-width: $lg) {
        grid-template-columns: repeat(auto-fill, 236px);
      }
    }
  }

  &.content-general-carousel,
  &.content-album-carousel {
    .carousel-item {
      .carousel-item-image {
        border-radius: 6px;
      }
    }

    .slider-control-centerleft {
      left: 16px !important;
    }

    .slider-control-centerright {
      right: 16px !important;
    }

    .slider-control-bottomcenter {
      display: none;
    }

    .custom-prev-button,
    .custom-next-button {
      background-color: white !important;
    }
  }

  &.content-text-carousel {
    > div {
      a.carousel-item.text-carousel.in-dev-mode {
        display: none;
      }

      div.mgnlEditorBar.focus ~ a.carousel-item.text-carousel.in-dev-mode {
        display: flex;
      }
    }

    .slider {
      a.carousel-item.text-carousel.in-dev-mode {
        display: flex;
      }

      margin: 0 auto;
      width: calc(100% - 32px) !important;

      .slider-control-bottomcenter {
        .paging-item button svg {
          background: #000;
          border-radius: 100%;
          height: 10px;
          width: 10px;
        }
      }
    }

    max-width: 992px;
    padding: 48px 16px;

    @media only screen and (max-width: $lg) {
      max-width: calc(100% - 120px);
    }

    @media only screen and (max-width: $md) {
      margin: auto;
      max-width: 264px;
    }
  }

  .no-controls {
    .custom-prev-button,
    .custom-next-button {
      display: none;
    }
  }

  .slider {
    .custom-prev-button,
    .custom-next-button {
      border-radius: 50%;
      height: 44px;
      position: relative;
      width: 44px;

      &::after {
        background-repeat: no-repeat;
        content: '';
        display: block;
        height: 30px;
        left: 16px;
        position: absolute;
        top: 7px;
        width: 15px;
      }
    }

    .custom-prev-button {
      &::after {
        background-image: url("data:image/svg+xml;utf8,<svg aria-hidden='true' focusable='false' data-prefix='far' data-icon='chevron-left' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'><path fill='currentColor' d='M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z'></path></svg>");
        left: 14px;
      }
    }

    .custom-next-button {
      &::after {
        background-image: url("data:image/svg+xml;utf8,<svg aria-hidden='true' focusable='false' data-prefix='far' data-icon='chevron-right' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'><path fill='currentColor' d='M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z'></path></svg>");
      }
    }

    .slider-control-bottomcenter {
      margin: 16px 0 0;
      top: 100%;

      ul {
        column-gap: 10px;
      }
    }

    @media only screen and (max-width: $md) {
      .slider-control-centerleft,
      .slider-control-centerright {
        display: none;
      }
    }
  }

  .carousel-title-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .carousel-title {
      margin-top: 0;
    }

    .view-all {
      background-color: var(--light_gray);
      border-radius: 2px;
      color: black;
      font-size: 14px;
      font-weight: bold;
      margin-left: auto;
      padding: 5px 10px;
      text-align: center;
    }
  }

  @media only screen and (min-width: $md) {
    &.has-single-item {
      > div {
        > * {
          margin-left: auto;
          margin-right: auto;
          max-width: 397px;

          &.modal-wrapper {
            max-width: unset;
          }
        }
      }

      &.content-text-carousel {
        > div {
          > * {
            max-width: unset;
          }
        }
      }
    }
  }
}

.text-white {
  .content-text-carousel {
    .custom-prev-button,
    .custom-next-button {
      background-color: var(--white) !important;
    }
    .slider {
      .slider-control-bottomcenter {
        .paging-item button svg {
          background: var(--white);
          fill: var(--white);
        }
      }
    }
  }
}
