@import '../../styles/_variables.scss';
.locations {
    margin: auto;
    a:hover {
        text-decoration: none !important;
    }
    .location-link {
        border-bottom: solid 2px #f4f4f4;
    }
    .locations-listing {
        max-width: 622px;
        margin: 0 auto;
        border: solid 2px #f4f4f4;
        position: relative;
        white-space: nowrap;
        h4.location-heading {
            color: var(--gray);
            font-size: var(--f-paragraph_large);
            font-weight: var(--fw-regular);
        }
        .location-heading {
            display: flex;
            align-items: center;
            padding: 14px 1rem 10px;
            font-size: 1.3rem;
            font-weight: 600;
            margin: 0;
            border-bottom: solid 2px #f4f4f4;
            white-space: normal;
            .church-online {
                display: flex;
                align-items: center;
                h6 {
                    margin: 0;
                    font-size: 1.3rem;
                    font-weight: 600;
                    letter-spacing: .04rem;
                    color: #1c2023;
                    text-align: left;
                    margin-left: 10px;
                }
            }
            .starts-soon {
                color: #404041;
                background-color: #f6f6f6;
                margin-left: 1rem;
                padding: 0.125rem 0.5rem;
                border-radius: 4px;
                font-size: 14px;
                line-height: 160%;
                font-weight: 400;
                &.active {
                    color: #cc2e00;
                    background-color: #ffebe6;
                }
            }
        }
        .locations-item {
            border-bottom: solid 2px #f4f4f4;
            .locations-state {
                font-family: var(--font-family);
                transition: all 0.3s ease-in-out;
                padding: 14px 1rem 10px;
                color: #1c2023;
                border-bottom: 1px solid transparent;
                font-size: 1.3rem;
                height: 64px;
                font-weight: 600;
                letter-spacing: .04rem;
                width: 100%;
                text-align: left;
            }
            &.active  {
                .sub-locations {
                    display: unset;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    overflow-y: auto;
                    background-color: #FFF;
                }    
            }
            .sub-locations {
                display: none;
                .state-title {
                    font-family: inherit;
                    font-size: 2em;
                }
                .view-all-state {
                    font-size: 1rem;
                    font-weight: 600;
                    padding: 16px;
                    border-bottom: 2px solid #f4f4f4;
                    color: var(--social_blues);
                    width: 100%;
                    display: flex;
                    align-items: center;
                    span {
                        margin-left: 10px;
                    }
                }
                .locations-list-item {
                    display: flex;
                    padding: 16px;
                    border-bottom: solid 2px #f4f4f4;
                    .location-icon {
                        border-radius: 100%;
                        width: 30px;
                        height: 30px;
                        background-color: #404041;
                        margin-right: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #FFF;
                        font-weight: bold;
                        &.coming-soon{
                            background-color: var(--card-link);
                        }
                    }
                    .location-detail {
                        flex-basis: calc(100% - 30px);
                        white-space: normal;
                        .title {
                            font-size: 1.3rem;
                            font-weight: 600;
                            letter-spacing: .04rem;
                            margin: 0;
                        }
                        .address {
                            margin: 0;
                            color: #444;
                            white-space: pre-wrap;
                        }
                    }
                }
            }
        }
    }
    .locations-map {
        width: 100%;
        height: 60vh;
        .location-pin {
            background-color: #444;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #FFF
        }
        .locations-map {
            height: 60vh;
            
        }
    }
}

@media screen and (min-width: $lg) {
    .locations {
        width: 100%;
        display: flex;
        height: unset;
        max-height: calc(98vh - 81px);
        .locations-listing {
            order: 1;
            max-width: 40em;
            min-width: 25em;
            height:unset;
            max-height: calc(98vh - 81px);
            .container-desktop {
                overflow: hidden;
                overflow-y: auto;
                max-height: 100%;
            }
        }
        .locations-map {
            order: 2;
            height:unset;
            max-height: calc(98vh - 81px);
        }
    }
}