@import '../../styles/_variables.scss';

.embed-item {
  background-color: #fff;
  padding: 32px !important;
  text-align: center;

  .form-wrapper {
    margin: 0 auto;
    width: 640px;
  }

  iframe.iframe-100vh {
    height: 100vh;
    max-height: 100%;
  }

  &.transparent {
    background-color: transparent;
  }
}

@media screen and (max-width: $md) {
  .embed-item {
    .form-wrapper {
      width: 100%;
    }

    iframe {
      width: 100%;
    }
  }
}
