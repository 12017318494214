@import '../../../styles/_variables.scss';

.related-content-carousel {
  .carousel-title {
    align-items: center;
    display: flex;
  }

  .extra-icon {
    height: 32px;
    margin-right: 12px;
    vertical-align: middle;
    width: 32px;
  }
}

@media screen and (min-width: $lg) {
  .related-content-carousel {
    .extra-icon {
      height: 36px;
      margin-right: 18px;
      width: 36px;
    }
  }
}
