@import '../../../styles/_variables.scss';

.podcast-content-subscribe {
  display: flex;
  flex-direction: column;

  .podcast-content {
    margin-bottom: 32px;

    .description {
      font-size: 20px;
      font-style: normal;
      font-weight: normal;
      line-height: 160%;
    }

    .btn-modal {
      background: #009ecc;
      border-radius: 200px !important;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      line-height: 100%;
      padding: 16px 32px;
      text-align: center;
      width: 100%;
    }

    .podcast-button {
      display: flex;
      flex-direction: column;

      .btn {
        margin-top: var(--spacing-more_slight_tight);
        width: 100%;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  .podcast-subscribe {
    background-color: #f6f6f6;
    margin-left: -16px;
    margin-right: -16px;

    .title {
      font-size: 24px;
    }

    .button-item-listing {
      > div {
        grid-template-columns: repeat(1, 1fr);
        width: 100%;

        .btn {
          border: 1px solid rgba(64, 64, 65, 0.4);
          max-width: 100%;
          width: 100%;

          .button-text {
            color: var(--black);
          }

          .icon {
            height: 27px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $md) {
  .podcast-content-subscribe {
    flex-direction: row;
    justify-content: space-between;

    .podcast-content {
      margin-bottom: 0;
      margin-right: 32px;
      width: calc(100% - (352px + 32px));

      .btn-modal {
        width: auto;
      }

      .podcast-button {
        display: block;

        .btn {
          display: inline-block;
          margin-top: var(--spacing-more_slight_tight);
          width: auto;

          &:first-child {
            margin-right: var(--spacing-more_slight_tight);
          }
        }
      }
    }

    .podcast-subscribe {
      box-sizing: border-box;
      margin-right: 0;
      min-width: 352px;
      width: 352px;
    }
  }
}

@media screen and (min-width: $lg) {
  .podcast-content-subscribe {
    .podcast-content {
      width: calc(100% - (390px + 32px));

      .title {
        font-size: 40px;
        margin-bottom: var(--spacing-normal);
      }

      .description {
        line-height: 150%;
        margin-bottom: var(--spacing-relaxed);
      }
    }

    .podcast-subscribe {
      min-width: 390px;
      padding: 30px;
      width: 390px;
    }
  }
}
