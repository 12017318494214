@import '../../../styles/_variables.scss';

.location-contact-card-v2 {
  padding: 48px 0px;

  .contact-card-header {
    margin: auto;
    margin-bottom: 24px;
    max-width: 450px;

    h2 {
      font-size: 32px;
      font-weight: 900;
      line-height: var(--line-height-tighter);
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    margin-bottom: 24px;
    max-width: 450px;

    .contact-card-pastor {
      align-items: center;
      column-gap: 8px;
      display: flex;
      justify-content: center;
      margin-bottom: 24px;

      img {
        border-radius: 100%;
        height: 80px;
        width: 80px;
      }

      >div {
        display: flex;
        flex-direction: column;

        h3 {
          font-size: 20px;
          font-weight: 700;
          line-height: var(--line-height-normal);
        }

        p {
          color: var(--gray-80);
          font-size: 16px;
          font-weight: 400;
          line-height: var(--line-height-normal);
        }
      }
    }

    .contact-card-address {
      margin-bottom: 16px;

      h3 {
        font-size: 16px;
        font-weight: 700;
        line-height: var(--line-height-normal);
      }

      a:hover {
        text-decoration: none;
      }

      p {
        color: inherit;
        font-size: 16px;
        font-weight: 400;
        line-height: var(--line-height-normal);
        padding-left: 32px;
      }

      >div {
        align-items: center;
        column-gap: 8px;
        display: flex;

        .icon {
          height: 24px;
          width: 24px;
        }
      }
    }

    .contact-card-social-media {
      display: flex;
      flex-direction: column;
      row-gap: 16px;

      a {
        align-items: center;
        column-gap: 8px;
        display: flex;
        font-size: 16px;
        font-weight: 400;
        line-height: var(--line-height-normal);

        &:hover {
          text-decoration: none;
        }
      }

      .icon {
        height: 24px;
        width: 24px;
      }
    }
  }

  .contact-card-connect {
    border-top: 0.5px solid var( --light-grayscale);
    padding-top: 24px;
    margin: auto;
    max-width: 450px;

    >div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 16px;
    }
  }
}

.location-contact-card-v2.modal-open {
  padding: 0px;

  .container {
    .contact-card-header {
      margin-bottom: 40px;

      h2 {
        font-size: 32px;
        font-weight: 900;
        line-height: var(--line-height-tighter);
      }
    }

    .content {
      align-items: normal;
      margin-bottom: 40px;
    }

    .contact-card-connect {
      h3 {
        font-size: 16px;
        font-weight: 700;
        line-height: var(--line-height-normal);
      }

      >div {
        column-gap: 24px;
      }
    }
  }
}

@media screen and (min-width: $md) {
  .location-contact-card-v2 {
    .content {
      align-items: center;

      .contact-card-pastor {
        margin-bottom: 32px;

        >div {
          h3 {
            font-size: 24px;
            line-height: var(--line-height-tighter);
          }

          p {
            font-size: 20px;
            font-weight: 400;
            line-height: var(--line-height-normal);
          }
        }
      }

      .contact-card-address {
        h3 {
          font-size: 24px;
          line-height: var(--line-height-tighter);
        }

        p {
          font-size: 20px;
        }
      }

      .contact-card-social-media {
        row-gap: 4px;

        a {
          font-size: 20px;
        }
      }
    }

    .contact-card-connect {
      h3 {
        font-size: 24px;
        line-height: var(--line-height-tighter);
      }

      >div {
        column-gap: 32px;
        flex-direction: row;
      }
    }
  }

  .location-contact-card-v2.modal-open {
    .container {
      .contact-card-header {
        margin-bottom: 56px;
      }

      .content {
        align-items: center;
        margin-bottom: 60px;
      }

      .contact-card-connect {
        h3 {
          font-size: 24px;
          font-weight: 700;
          line-height: var(--line-height-tighter);
        }

        >div {
          column-gap: 24px;
        }
      }
    }
  }
}

@media screen and (min-width: $lg) {
  .location-contact-card-v2 {
    padding: 152px 0px 112px;

    &:not(.modal-open) {
      .contact-card-header {
        margin-bottom: 48px;
        max-width: 800px;

        h2 {
          font-size: 40px;
        }
      }

      .content {
        display: flex;
        margin-bottom: 48px;
        max-width: 100%;

        .inner-container {
          align-items: flex-start;
          column-gap: 46px;
          display: flex;
          justify-content: center;
        }

        .contact-card-pastor {
          flex-direction: column;
          margin-bottom: 0;
          row-gap: 8px;

          >div {
            align-items: center;
          }
        }
      }

      .contact-card-connect {
        max-width: 800px;
      }
    }
  }
}
