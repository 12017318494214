@import '../../styles/_variables.scss';

.rich-text {
  font-family: var(--font-family);
  font-size: var(--f-normal);
  font-weight: normal;

  blockquote {
    border-left: 4px solid var(--blue);
    margin: 0;
    margin-bottom: var(--spacing-normal);
    padding: var(--spacing-normal);
    padding-bottom: 0;
    padding-top: 0;
  }

  > *:first-child {
    margin-top: 0;
    padding-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  p {
    line-height: 160%;
    margin: 0 0 1rem;
  }

  &.no-padding {
    padding: 0;
  }

  ul {
    list-style: initial;
    padding-left: 40px;
  }

  &.text-white {
    p {
      color: var(--gray-10);
    }
  }

  ul,
  ol {
    color: var(--gray);
    margin: 0 0 1rem;
  }
}
