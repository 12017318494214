@import '../../styles/_variables.scss';

.media-asset-wrapper {
  &.normal-width {
    img {
      width: auto;

      @media screen and (max-width: $md) {
        width: 100%;
      }
    }
  }
}
