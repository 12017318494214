@import '../../styles/_variables.scss';

.location-template {
  @media screen and (min-width: $lg) {
    .banner-wrapper {
      .banner-content-and-button {
        box-sizing: border-box;
        justify-content: center;
        padding: 28px 0;

        .banner-content p {
          font-style: var(--f-normal);

          .btn {
            height: 40px;
          }
        }
      }
    }
  }
}
