.open-content-list-item {
  border-radius: var(--radius-s);
  box-shadow: var(--shadow-m);
  display: flex;
  position: relative;
  transition: box-shadow 0.5s, transform 0.5s;

  &:hover {
    &:not(.no-link-trigger) {
      box-shadow: var(--shadow-l);
      transform: translateY(-4px);
    }
  }

  &.vertical-cards {
    background-color: #fff;
    flex-direction: column;

    .open-content-feature-image {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-top-left-radius: var(--radius-s);
      border-top-right-radius: var(--radius-s);
      padding-top: 50%;
      width: 100%;

      &.imgixBG {
        height: unset;
      }
    }

    .open-content {
      .card-content {
        line-height: var(--line-height-normal);
      }
    }
  }

  &.horizontal-cards {
    background-color: #fff;
    padding: 12px;

    .open-content-feature-image {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100px;
      margin-right: 12px;
      width: 100px;
    }

    .open-content {
      flex-basis: calc(100% - 112px);
      padding: 0;

      .card-title {
        font-size: var(--f-feature_paragraph);
      }
    }

    .card-link {
      margin: var(--spacing-tighter) 0;
    }
  }

  .open-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: var(--spacing-relaxed) var(--spacing-normal);

    .card-title {
      font-size: 24px;
      font-weight: bold;
      line-height: var(--line-height-tightest);
      margin-bottom: 4px;
      margin-top: 0;
      text-align: left;
    }

    .card-subtitle {
      color: var(--gray-80);
      font-size: var(--f-paragraph_large);
      line-height: var(--line-height-tightest);
      margin-bottom: 1rem;
      margin-top: 0;
      text-align: left;
    }

    .card-content {
      color: var(--gray);
      font-size: var(--f-normal);
      line-height: var(--line-height-tighter);
      margin: 4px 0;
      text-align: left;
    }

    .card-link {
      color: var(--card-link);
      font-size: var(--f-normal);
      font-weight: bold;
      line-height: var(--line-height-tightest);
      margin-top: 8px;
      text-decoration: none;
    }
  }

  .no-mb {
    margin-bottom: 0;
  }

  .whole-card-link {
    box-sizing: border-box;
    height: 100%;
    position: absolute;
    width: 100%;
  }
}
