@import '../../../styles/_variables.scss';

.podcast-lead-gen-form {
  background-color: var(--gray-5);
  padding-bottom: var(--spacing-slightly_more_relaxed);
  padding-top: var(--spacing-slightly_more_relaxed);

  .content-and-form {
    flex-direction: column;
    margin: 0 auto;
    text-align: center;
    width: auto;
  }

  .embed-item {
    background-color: transparent;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100%;
  }

  .title {
    color: var(--grayscale);
    font-size: var(--f-subhead);
    font-style: normal;
    font-weight: var(--fw-black);
    line-height: var(--line-height-tighter);
    margin: 0;
    text-align: center;

    @media screen and (min-width: $lg) {
      font-size: var(--f-section_header_desktop);
      font-weight: var(--fw-black);
      line-height: var(--line-height-tighter);
    }
  }

  @media only screen and (min-width: $md) {
    .content-and-form {
      max-width: 576px !important; // 576px is the max width of Hubspot form.
    }
  }
}
