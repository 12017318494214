@import '../../styles/_variables.scss';

.is-reveal-open {
  left: 0;
  overflow-y: scroll;
  position: fixed;
  right: 0;

  @media only screen and (min-width: $lg) {
    overflow-y: visible;
    position: static;
  }
}

.reveal-overlay {
  background-color: rgba(10, 10, 10, 0.45);
  bottom: 0;
  left: 0;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 74px;
  z-index: 9998;

  &.hide {
    display: none;
  }

  &.show {
    display: block;
  }

  @media only screen and (min-width: $lg) {
    display: none !important;
  }
}

.careers-menu-modal {
  background-color: var(--white);
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.1),
    inset 0 5px 6px -4px rgba(0, 0, 0, 0.4);
  height: 100%;
  position: absolute;
  right: 0px;
  width: 15.625rem;
  z-index: 9999;

  .modal-navigation {
    margin-bottom: var(--spacing-normal);

    &:last-child {
      margin-bottom: var(--spacing-very_relaxed);
    }

    .modal-nav-title {
      font-size: var(--f-group_header);
      font-weight: bold;
      line-height: var(--line-height-normal);
      margin: 0 0 var(--spacing-tighter);
      padding: 0 var(--spacing-normal);
    }

    > ul {
      > li {
        a {
          color: #444;
          display: flex;
          flex-direction: column;
          font-weight: 600;
          line-height: var(--line-height-normal);
          padding: var(--spacing-slight_tight) var(--spacing-relaxed);
          text-decoration: none;

          &.active {
            box-shadow: inset 0.25rem 0 0 var(--card-link);
            font-weight: var(--fw-semi_bold);

            .modal-link-title {
              font-weight: var(--fw-semi_bold) !important;
            }
          }

          &:hover {
            background-color: #e7faff;

            .modal-link-title {
              color: var(--card-link-primary-active);
            }
          }

          .modal-link-title {
            color: black;
            font-size: 1.25rem;
            font-weight: var(--fw-semi_bold);
          }

          .modal-link-description {
            color: #444;
            font-size: var(--f-normal);
            font-weight: var(--fw-regular);
            margin: 0;
          }
        }

        > span {
          padding-left: 24px;
        }
      }

      > li.menu-folder {
        padding-bottom: var(--spacing-tighter);

        > span {
          display: inline-block;
          font-size: 1.25rem;
          font-weight: var(--fw-semi_bold);
          line-height: 160%;
          padding: var(--spacing-slight_tight) var(--spacing-relaxed);
          width: calc(100% - calc(var(--spacing-relaxed) * 2));

          &:first-of-type {
            padding-bottom: 0;
          }
        }

        .menu-sub-item {
          .modal-link-title {
            font-size: 1.25rem;
            font-weight: var(--fw-regular);
            padding-left: var(--spacing-more_slight_tight);
          }
        }
      }

      > li.menu-link {
        font-size: 1.5rem;
        line-height: 2rem;

        > a {
          padding-left: 24px;
        }
      }

      > .root-menu-item {
        border-bottom: 1px solid var(--gray-30);

        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }
}
