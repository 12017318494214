.multi-step-form {
  .step-indicator {
    background: #d2d2d9;
    border-radius: 32px;
    height: 4px;
    flex: 1;

    &.active-step-indicator {
      background-color: var(--black);
    }
  }
}
