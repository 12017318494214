@import '../../../styles/_variables.scss';

.talk-it-over {
  position: relative;

  .talk-it-over-btn-back {
    margin-bottom: var(--spacing-normal);

    .btn-back {
      color: #0ea9d6;
      font-size: 20px;
      font-weight: var(--fw-bold);
      left: 2%;
      text-align: left;
    }
  }

  .talk-it-over-content {
    .talk-it-over-item {
      .rich-text {
        padding: 0;

        p {
          margin-bottom: var(--spacing-normal);
        }
      }
    }
  }
}

.tio-modal {
  .modal {
    max-width: 768px;
  }
}

@media screen and (min-width: $lg) {
  .talk-it-over {
    .talk-it-over-btn-back {
      left: 0;
      position: absolute;
      top: 0;
    }

    .talk-it-over-content {
      margin: 0 auto;
      padding: var (--spacing-normal);
    }
  }
}
