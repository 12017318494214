@import '../../styles/_variables.scss';

.location-poster {
  position: relative;

  .location-content {
    margin-bottom: 64px;
  }

  .location-hero {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 376px;
  }

  .poster {
    height: 100%;
    margin-bottom: var(--spacing-bit_more_relaxed);

    .back-to-states-row {
      .back-to-states-link {
        align-items: center;
        border-bottom: 2px solid var(--lighter-gray);
        color: var(--blue);
        display: flex;
        font-size: 1rem;
        padding: 16px;
        text-decoration: none;
      }

      .back-arrow-linear-icon {
        color: var(--blue);
        height: 18px;
        margin-right: 10px;
        width: 10px;
      }
    }

    .poster-content {
      border-bottom: 1px solid var(--lighter-gray);

      .location {
        color: #000000;
        font-size: 24px;
        font-style: normal;
        font-weight: bold;
        line-height: 100%;
        margin: 0 0 var(--spacing-tighter);
      }

      .text-gray {
        color: #c6c6c6;
        font-weight: normal;
        margin: 0 0 var(--spacing-normal);
      }

      .poster-profile {
        border-radius: 100%;
        height: 80px;
        width: 80px;
      }

      .poster-name {
        font-size: 16px;
        font-style: normal;

        > h2 {
          font-size: 20px;
          margin: 0;
        }
      }
    }

    .poster-address {
      border-top: 1px solid var(--lighter-gray);

      > p {
        column-gap: 10px;
        padding: 0 var(--spacing-normal);

        .section-title {
          white-space: nowrap;
        }

        .address {
          p {
            color: var(--card-link-primary-lighter);
            font-weight: normal;
            line-height: 120%;
            text-align: right;
          }
        }

        span:last-child {
          font-weight: normal;
          line-height: 120%;
          text-align: right;

          a {
            color: var(--card-link-primary-lighter);
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .social-media {
        column-gap: 16px;
      }

      .social-link {
        font-size: 2rem;
        text-decoration: none;
      }
    }

    .poster-schedule {
      padding: var(--spacing-normal);

      &-separator {
        border-bottom: 1px solid var(--gray-10);
      }

      h3 {
        font-size: var(--f-subhead);
        font-weight: 700;
        margin: 0px;
        margin-bottom: var(--spacing-normal);
      }

      .tabs {
        margin-bottom: var(--spacing-normal);
      }

      .event-image-wrapper {
        border-radius: 12px;
        margin-bottom: var(--spacing-normal);

        &.dark-mode {
          color: var(--white) !important;

          * {
            color: var(--white);
          }
        }

        .event-background {
          align-items: center;
          background-position: center;
          border-radius: inherit;
          display: flex;
          flex-direction: column;
          gap: 16px;
          height: auto;
        }

        .event-image {
          display: flex;
          margin: auto;
        }
      }

      .poster-event {
        &-name {
          align-items: center;
          border-bottom: 1px solid var(--gray-10);
          display: flex;
          font-size: var(--f-normal);
          font-weight: 400;
          gap: var(--spacing-tighter);
          margin-bottom: var(--spacing-normal);
          padding: 8px 0;
          text-transform: uppercase;

          * {
            color: var(--black);
          }

          .icon {
            height: 24px;
            width: 24px;
          }

          p {
            margin: 0;
          }
        }

        &-date {
          font-size: var(--f-paragraph_large);
          font-weight: 700;
          margin: 0;
          margin-bottom: var(--spacing-tighter);
        }

        &-times {
          display: flex;
          flex-wrap: wrap;
          gap: var(--spacing-tighter);
        }

        &-time {
          background-color: #f2f2f2;
          border-radius: 2px;
          color: #3d3d40;
          font-size: var(--f-normal);
          font-weight: 400;
          padding: 6px 8px;
        }

        &-notes {
          margin-top: var(--spacing-normal);

          p {
            font-size: var(--f-normal);
            line-height: 20px;
            margin: 0;
          }
        }
      }
    }

    .btn-special-event {
      width: calc(100% - (var(--spacing-very_relaxed) * 2));
    }

    .prayer-request-linear-icon {
      height: 28px;
      width: 28px;
    }
  }

  @media screen and (min-width: $md) {
    .location-content {
      margin-bottom: 96px;
    }

    .poster {
      margin-bottom: var(--spacing-even_more_relaxed);

      .btn-special-event {
        display: inline-block;
        width: unset;
      }

      .poster-additional-info,
      .poster-schedule,
      .poster-address p {
        margin-left: auto;
        margin-right: auto;
        max-width: 640px;
      }
    }

    .location-card {
      margin: 0 auto;
      max-width: 504px;
    }
  }

  @media screen and (min-width: $lg) {
    .location-hero {
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .location-content {
      column-gap: 52px;
      display: flex;
      justify-content: flex-end;
      margin: 0 auto 96px;
      max-width: 956px;
      position: relative;
    }

    .poster {
      background: var(--white);
      border: 2px solid var(--lighter-gray);
      border-radius: 4px;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
      box-sizing: border-box;
      margin-bottom: 0;
      margin-top: 64px;
      width: 400px;

      .poster-schedule {
        .schedule-item:last-child {
          margin-bottom: var(--spacing-normal);
        }
      }

      .btn-special-event {
        width: calc(100% - (var(--spacing-very_relaxed) * 2));
      }
    }

    .location-card {
      margin: 376px 0 0;
      padding: var(--spacing-bit_more_relaxed) 0;
      width: 100%;
    }
  }
}
