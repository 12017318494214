@import '../../styles/_variables.scss';

.job-list {
  .jobs-list-count {
    color: var(--gray-80);
    font-size: var(--f-subhead);
    font-weight: bold;
    margin: var(--spacing-normal) 0;
    text-align: center;
  }

  .job-list-items {
    background: var(--white);
    box-sizing: border-box;
    margin: auto;
    margin-bottom: var(--spacing-slightly_more_relaxed);
  }

  a.job-item {
    display: block;
  }

  .job-item {
    border-top: 1px solid var(--gray-10);

    &:hover {
      text-decoration: none;
    }

    &:last-of-type {
      border-bottom: 1px solid var(--gray-10);
    }

    h3 {
      color: var(--gray);
      font-size: var(--f-subhead);
    }

    .job-team-title {
      color: var(--gray-50);
      font-size: var(--f-feature_paragraph);
      font-weight: var(--fw-regular);
      margin: 0;
    }

    .job-description {
      color: var(--gray);
      font-size: var(--f-normal);
      font-weight: var(--fw-regular);
      margin: 0;
    }
  }
}

.job-search-assistance {
  box-sizing: border-box;
  margin: auto;
  padding: 16px;

  h3 {
    color: var(--gray);
    font-size: var(--f-subhead_desktop);
    margin: 0;
  }

  p {
    color: var(--gray);
    font-size: var(--f-feature_paragraph);
    font-weight: var(--fw-regular);
    letter-spacing: -0.2px;
    margin: 0;
  }

  a {
    white-space: pre-wrap;

    &:hover {
      text-decoration: none;
    }
  }
}

.content-section {
  width: 100%;

  @media only screen and (min-width: $md) {
    max-width: $md;
  }

  @media only screen and (min-width: $lg) {
    max-width: 768px;
  }
}

.find-a-role {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  .browser-roles {
    padding-top: var(--spacing-even_extra_more_relaxed);

    h1 {
      margin-top: 0;
    }

    h2 {
      color: var(--gray);
      font-weight: var(--fw-regular) !important;
      margin-top: 0;
    }

    p {
      color: var(--gray-80);
      font-weight: var(--fw-regular) !important;
      margin-top: 0;
    }

    .team-select {
      appearance: none;
      background: var(--white);
      background-image: url('../../assets/angle-down.svg');
      background-position-x: calc(100% - 24px);
      background-position-y: calc(100% - 10px);
      background-repeat: no-repeat;
      border-radius: var(--radius-l);
      border: 0.0625rem solid #cccccc;
      height: 40px;
      line-height: var(--line-height-normal);
      max-width: 350px;
      text-align: center;
      text-align-last: center;
      width: 100%;

      &:focus-visible {
        outline: none;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }

  .job-search-assistance {
    h3 {
      font-size: var(--f-subhead);
    }
  }

  @media only screen and (min-width: $md) {
    .browser-roles {
      h1 {
        font-size: var(--f-section_header_desktop) !important;
      }

      h2 {
        font-size: var(--f-subhead) !important;
      }
    }

    .job-list {
      .job-item {
        h3 {
          font-size: var(--f-section_header);
        }
      }
    }

    .job-search-assistance {
      h3 {
        font-size: var(--f-section_header);
      }
    }
  }

  @media only screen and (min-width: $lg) {
    .browser-roles {
      h1 {
        font-size: var(--f-title_desktop) !important;
      }

      h2 {
        font-size: var(--f-section_header) !important;
      }
    }
  }
}

.teams {
  margin: auto;
  max-width: 75rem;

  .team-cards {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .team-card {
    box-sizing: border-box;
    width: 100%;

    a {
      background-color: var(--white);
      border-radius: var(--radius-xs);
      box-shadow: 0 1px 4px rgba(41, 41, 42, 0.25);
      height: 100%;
      width: 100%;

      &:hover {
        box-shadow: 0px 4px 12px rgba(45, 45, 46, 0.25);
        cursor: pointer;
        text-decoration: none;
        transition: 0.25s;

        h4 {
          color: var(--card-link-primary-lighter);
          transition: 0.25s;
        }
      }

      img {
        border-radius: 4px 4px 0 0;
        max-height: 206px;
        object-position: center;
      }

      h4 {
        font-size: var(--f-subhead);
        line-height: var(--line-height-tighter);
        margin: 0;
      }

      p {
        color: var(--gray-50);
        margin: 0;
      }
    }
  }

  @media only screen and (min-width: $md) {
    .team-cards {
      flex-direction: row;
    }

    .team-card {
      max-width: 50%;
    }
  }

  @media only screen and (min-width: $lg) {
    .team-card {
      max-width: 33.33%;
    }
  }
}

.team {
  .team-hero {
    background-color: var(--black);
    height: fit-content;
    min-height: 240px;

    .overlay {
      background: rgba(45, 45, 46, 0.7);
      box-sizing: border-box;
      min-height: inherit;
      width: 100%;
    }

    .container {
      display: flex;
      justify-content: center;
      position: relative;
    }

    .content {
      margin-top: var(--spacing-very_relaxed);

      h2 {
        font-size: var(--f-section_header_desktop);
        font-weight: var(--fw-black);
        margin: 0;
      }

      p {
        display: none;
      }

      a {
        align-items: center;
        color: var(--white);
        display: inline-flex;
        font-size: var(--spacing-kinda_relax);
        gap: var(--spacing-tightest);
        text-decoration: none;
      }
    }

    .back-button {
      align-items: center;
      color: var(--white);
      display: flex;
      gap: var(--spacing-tightest);
      left: 0;
      margin-left: var(--spacing-normal);
      position: absolute;
      text-decoration: none;
      top: 0;
    }
  }

  .team-description {
    color: var(--gray);
    font-size: var(--f-feature_paragraph);
    letter-spacing: -0.2px;
    margin: 0;
    padding: var(--spacing-normal);
    text-align: center;
  }

  .job-list {
    margin: auto;
    margin-bottom: var(--spacing-bit_more_relaxed);
    padding: 0 var(--spacing-normal);

    .job-list-items {
      margin-bottom: var(--spacing-bit_more_relaxed);
    }

    .jobs-list-count {
      font-weight: bold;
      margin-bottom: var(--spacing-normal);
      margin-top: var(--spacing-normal);
    }

    .job-item {
      h3 {
        font-size: var(--f-subhead);
      }
    }
  }

  .job-search-assistance {
    h3 {
      font-size: var(--f-subhead);
    }
  }

  @media only screen and (min-width: $md) {
    .team-hero {
      .back-button {
        margin-left: var(--spacing-very_relaxed);
      }

      .content {
        margin-bottom: var(--spacing-most_relaxed);

        h2 {
          font-size: var(--spacing-slightly_more_relaxed);
        }

        p {
          display: block;
          font-size: var(--f-feature_paragraph);
          letter-spacing: -0.2px;
          line-height: 1.6;
          margin: auto;
        }
      }
    }

    .team-description {
      display: none;
    }

    .job-list {
      margin-top: -10rem;
      max-width: $md;
      padding: 0;

      .job-list-items {
        border-radius: var(--radius-xs);
        box-shadow: 0 0.0625rem 0.25rem rgba(41, 41, 42, 0.25);
        margin-bottom: var(--spacing-bit_more_relaxed);
        padding: var(--spacing-normal);
      }

      .job-item {
        h3 {
          font-size: var(--f-subhead_desktop);
        }
      }

      .jobs-list-count {
        font-size: var(--f-subhead);
      }
    }

    .job-search-assistance {
      max-width: $md;

      h3 {
        font-size: var(--f-section_header);
      }
    }
  }

  @media only screen and (min-width: $lg) {
    .job-search-assistance {
      max-width: 768px;
    }

    .job-list {
      box-sizing: content-box;
      max-width: 768px;

      .jobs-list-count {
        font-size: var(--f-section_header);
        margin-top: var(--spacing-normal);
      }

      .job-item {
        h3 {
          font-size: var(--f-section_header);
        }
      }
    }
  }
}
