@import '../../../styles/_variables.scss';

.podcast-collection-subscribe {
  background: linear-gradient(315deg, #004e7c -9.37%, #00788c 108.33%);
  box-sizing: border-box;
  min-height: 826px;
  position: relative;

  .container {
    .title {
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      margin: 0 0 30px;
      position: relative;
    }

    .button-group-wrapper {
      align-items: center;
      justify-content: center;
    }
  }

  .iphone-img {
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain !important;
    bottom: 0;
    box-sizing: border-box;
    height: 100%;
    left: 0;
    margin: auto;
    max-width: 450px;
    position: absolute;
    right: 0;
    width: 100%;
  }

  .button-item-listing {
    z-index: 1;
  }

  @media screen and (min-width: $md) {
    min-height: 500px;

    .container {
      .title {
        margin-bottom: 18px;
        max-width: 50%;
      }

      .button-group-wrapper {
        width: 50%;
      }

      .channel-buttons-group {
        margin: 0;
      }

      .button-item-listing {
        flex-direction: column;

        .btn {
          max-width: 400px;
        }

        > div {
          flex-direction: column;
        }
      }
    }

    .iphone-img {
      bottom: unset;
      box-sizing: content-box;
      left: unset;
      max-width: unset;
      top: 0;
      width: calc(100% - 370px); //30.5rem;
    }
  }

  @media screen and (min-width: $lg) {
    min-height: 325px;

    .container {
      .title {
        font-size: 32px;
        max-width: 632px;
      }

      .button-group-wrapper {
        justify-content: flex-start;
        width: auto;
      }

      .button-item-listing {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        width: 650px;

        > div {
          flex-direction: row;
          margin: -16px;

          .btn {
            margin: 16px;
            max-width: 300px;
            width: 100%;
          }
        }
      }
    }

    .iphone-img {
      left: unset;
      max-width: unset;
      width: calc(100% - 632px);
    }
  }
}
