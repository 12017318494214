@import '../../../styles/_variables.scss';

.past-series {
  padding-bottom: 32px;

  &.early-childhood-based,
  &.series-based {
    background: var(--lighter-gray);
    padding: 32px 0;
  }

  &.early-childhood-based {
    .open-content-list {
      .open-content-list-item {
        &:hover {
          .open-content-feature-image {
            box-shadow: 0 4px 28px rgba(255, 248, 0, 0.3);
          }
        }
      }
    }
  }

  .past-series-title {
    align-items: center;
    display: flex;
    font-size: 24px;
    margin-bottom: 12px;
    margin-top: 0;
    text-transform: capitalize;
  }

  .extra-icon {
    height: 32px;
    margin-right: 12px;
    vertical-align: middle;
    width: 32px;
  }
}

@media screen and (min-width: $md) {
  .past-series {
    &.early-childhood-based,
    &.episode-based {
      .open-content-list > div {
        @media screen and (max-width: 1023px) {
          grid-template-columns: repeat(
            auto-fill,
            minmax(calc((100% - var(--spacing-normal)) / 2), 1fr)
          );
        }
      }
    }

    .past-series-title {
      margin-bottom: 32px;
    }
  }
}

@media screen and (min-width: $lg) {
  .past-series {
    .extra-icon {
      height: 36px;
      margin-right: 18px;
      width: 36px;
    }
  }
}
