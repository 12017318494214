.form-heading {
  .form-logo {
    max-height: 211px;
    object-fit: cover;
  }

  .form-description {
    text-align: left;
  }
}
