@import "../../styles/_variables.scss";

.location-button {
  background: linear-gradient(315deg, #009ecc 1.04%, #00b3e5 104.17%);

  .container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-bit_more_relaxed);
    justify-content: center;

    @media screen and (min-width: $md) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    .btn-location {
      align-items: center;
      background: #ffffff;
      border-radius: 56px;
      column-gap: var(--spacing-normal);
      display: flex;
      justify-content: center;
      padding: var(--spacing-slight_tight) var(--spacing-bit_more_relaxed);
      text-decoration: none !important;

      &:hover {
        background-color: var(--blue-bg-light);
        border-color: var(--blue-bg-light);
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
        color: #00b2e5;
      }

      span {
        font-size: 16px;
        line-height: 25px;
        text-align: center;
      }

      @media screen and (min-width: $md) {
        padding: var(--spacing-normal) var(--spacing-bit_more_relaxed);

        span {
          font-size: 20px;
        }
      }
    }
  }
}
