.file {
  display: flex;
  flex-direction: column;
  text-align: left;

  label {
    color: var(--grayscale);
    font-size: var(--f-paragraph_large);
    font-weight: bold;
    line-height: var(--line-height-normal);
  }

  .form-group {
    position: relative;

    input {
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .file-style {
      align-items: center;
      border: 1px dashed #c6c6c6;
      border-radius: 4px;
      box-sizing: border-box;
      display: flex;
      font-size: var(--f-paragraph_large);
      height: 150px;
      justify-content: center;
      padding: 15px;
      width: 100%;
      z-index: 1;
    }
  }

  span {
    color: #666667;
    font-size: 13.44px;
    font-style: normal;
    font-weight: normal;
    line-height: 17px;
  }

  .file-uploaded {
    .file-item {
      border: 1px dashed #c6c6c6;
      display: flex;
      justify-content: space-between;

      .file-title {
        align-items: center;
        column-gap: 17px;
        display: flex;

        span {
          font-size: 20px;
          font-style: normal;
          font-weight: normal;
          line-height: 160%;
        }
      }
    }
  }
}
