@import '../../../styles/_variables.scss';

.song-detail {
  .album-link {
    width: max-content;
  }

  .behind-the-music {
    .album-image-wrapper {
      .album-image {
        height: 75px;
        margin-right: 16px;
        width: 75px;
      }
    }

    .song-resources-links {
      .link-text {
        font-size: 16px;
      }
    }

    .song-listing {
      .song-item {
        border-bottom: 1px solid #ececec;
        padding: 10px 0;

        &:first-of-type {
          border-top: 1px solid #ececec;
        }

        .song-item-link {
          &:hover {
            text-decoration: none;
          }

          &.current {
            color: var(--black);
          }
        }
      }
    }
  }
  .modal {
    max-width: 500px;
    padding: 32px 16px;

    .share-link-modal-content {
      text-align: center;

      .album-image {
        height: 100px;
        margin: auto;
        width: 100px;
      }

      .share-title {
        font-size: 36px;
        font-weight: bold;
        line-height: 160%;
        margin-bottom: 24px;
        margin-top: 0;
      }

      .album-title {
        font-size: 24px;
        font-weight: bold;
        line-height: 160%;
        margin-bottom: 16px;
        margin-top: 8px;
      }

      .link-copy-wrapper {
        position: relative;

        .link-input-box {
          border: 1px solid #efefef;
          border-radius: 47px;
          box-sizing: border-box;
          color: #c6c6c6;
          font-size: 14px;
          height: 50px;
          line-height: 25px;
          padding: 12px 24px;
          width: 100%;
        }

        .btn-copy-link {
          position: absolute;
          right: 5px;
          top: 5px;

          &.copied {
            background: #008bb2;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $md) {
  .song-detail {
    .lyric-and-btm {
      display: flex;

      > div {
        flex: 0 50%;
      }
    }

    .behind-the-music {
      .album-image-wrapper {
        .album-image {
          height: 100px;
          width: 100px;
        }
      }
    }

    .modal {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
