@import '../../styles/_variables.scss';

.careers-header {
  .navigation {
    align-items: center;
    display: flex;
    height: 74px;
    justify-content: space-between;

    .nav-logo {
      align-items: center;
      display: flex;
      flex: 0 0 50%;

      > img {
        max-height: 24px;
        max-width: fit-content;
        object-fit: contain;
        width: auto;
      }
    }

    .nav-desktop {
      color: var(--gray-80);
      display: none;
      margin: 0 1rem 0 0;
      height: 100%;

      &.has-children {
        padding-right: 24px !important;

        &:hover {
          border-bottom: 6px solid var(--social_blues);
          height: calc(100% - 4px);
        }
      }

      .modal-link-title.has-children::after {
        content: '';
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid var(--gray-50);
        display: inline-block;
        margin-left: 8px;
        position: absolute;
        top: 35px;
      }

      &:hover {
        .modal-link-title.has-children::after {
          border-top: 5px solid var(--social_blues);
        }
      }
    }

    .menu-nav {
      align-items: center;
      display: flex;
      text-transform: uppercase;

      .hamburger-menu {
        align-items: center;
        display: flex;
        position: relative;
        text-decoration: none;
        white-space: nowrap;

        > span {
          display: none;
          font-weight: 600;
          margin-left: 6px;
        }

        &:hover {
          > span {
            color: var(--card-link);
          }
        }

        > .hamburger {
          display: flex;
          flex-direction: column;
          margin-right: 3px;
          min-height: 16px;
          min-width: 26px;
          position: relative;

          span {
            background-color: var(--gray-100);
            height: 3px;
            position: absolute;
            top: 0;
            transition: transform 0.4s, top 0.2s ease-in;
            width: 26px;
          }

          span:nth-child(2) {
            top: 7px;
          }

          span:nth-child(3) {
            top: 14px;
          }

          &.active {
            span:nth-child(1),
            span:nth-child(2) {
              top: 5px;
              transform: rotate(45deg);
            }

            span:nth-child(3) {
              top: 5px;
              transform: rotate(-45deg);
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: $md) {
  .careers-header {
    .navigation {
      .nav-logo {
        flex: 0 0 25%;

        > img {
          height: 24px;
          max-width: fit-content;
          object-fit: contain;
          width: auto;
        }
      }

      .menu-nav {
        .hamburger-menu {
          > img {
            margin-right: var(--spacing-normal);
          }
        }
      }
    }
  }
}

@media screen and (min-width: $lg) {
  .careers-header {
    .navigation {
      .menu-nav {
        display: none;
        position: relative;

        .hamburger-menu {
          display: none;
        }
      }

      .nav-desktop {
        display: unset;
      }

      .main-nav {
        align-items: center;
        display: flex;
        height: 100%;

        > li.nav-desktop {
          color: var(--gray-80);
          display: flex;
          height: 100%;
          margin: auto;
          margin-right: var(--spacing-normal);
          position: relative;
          padding: 0px 12px;
          vertical-align: middle;

          &.active {
            border-bottom: 4px solid var(--black);
            color: var(--black);
            height: calc(100% - 4px);

            > a,
            > div.has-children {
              color: var(--black);
            }
          }

          &:hover {
            border-bottom: 4px solid var(--social_blues);

            > a,
            > div.has-children {
              color: var(--social_blues);
              text-decoration: none;
            }

            .sub-nav {
              background-color: white;
              box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.15);
              box-sizing: border-box;
              color: var(--gray-80);
              display: block;
              height: min-content;
              position: absolute;
              right: 0px;
              text-decoration: none;
              top: 74px;
              width: 100%;
              z-index: 99;
              -moz-box-sizing: border-box;
              -webkit-box-sizing: border-box;

              > li.nav-desktop {
                background-color: white;
                display: contents;
                margin: 0px;
                text-align: center;

                &:hover {
                  > a:not(.active) {
                    background-color: azure;
                    color: var(--social_blues);
                    text-decoration: none;
                  }
                }

                > a {
                  box-sizing: border-box;
                  display: block;
                  font-size: var(--f-normal);
                  height: auto;
                  line-height: 24px;
                  padding: 0.5rem 1rem;
                  right: 4px;
                  text-align: start;
                  text-transform: none;
                  width: 100%;
                }
              }
            }
          }

          a,
          > div.has-children {
            color: var(--gray-80);
            cursor: pointer;
            font-size: var(--f-small);
            font-weight: 600;
            height: auto;
            line-height: 74px;
            text-decoration: none;
            text-transform: uppercase;

            &.active {
              background-color: transparent;
              color: var(--black);
            }
          }

          .sub-nav {
            display: none;
          }
        }
      }
    }
  }
}
