.select {
  display: flex;
  flex-direction: column;
  text-align: left;

  label {
    color: var(--grayscale);
    font-size: var(--f-paragraph_large);
    font-weight: bold;
    line-height: var(--line-height-normal);
  }

  .select-input {
    position: relative;

    select {
      appearance: none;
      border: 1px solid #c6c6c6;
      border-radius: 4px;
      box-sizing: border-box;
      font-size: var(--f-paragraph_large);
      padding: 15px;
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      width: 100%;
    }

    svg {
      position: absolute;
      right: 15px;
      top: 19px;
    }
  }

  span {
    color: #666667;
    font-style: normal;
    font-size: 13.44px;
    font-weight: normal;
    line-height: 17px;
  }
}
