@import '../../../styles/_variables.scss';

.promo-wrapper {
  .promo-hero {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: calc(100vw / (3000 / 680));
    position: relative;
    z-index: -100;

    .modal {
      max-width: 1200px;
    }
  }

  .watch-promo {
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    height: 46px;
    line-height: 100%;
    margin-bottom: 4rem;
    margin-top: -23px;
    padding: 18px 32px;
    width: calc(100% - 32px);

    .button-text {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media screen and (min-width: $md) {
  .promo-wrapper {
    .promo-hero {
      height: calc(100vw / (3000 / 680));
    }

    .watch-promo {
      height: 56px;
      margin-top: -28px;
      min-width: 213px;
      width: auto;
    }
  }
}
