@import '../../styles/_variables.scss';

.media-thumbnail {
  color: unset;
  display: flex;
  text-decoration: none !important;

  &.carousel-media {
    flex-direction: column;

    .media-image {
      margin: 0 0 var(--spacing-normal);
      width: unset;
    }

    .media-content {
      width: unset;

      .media-title {
        font-size: var(--f-normal);
      }

      .part-number {
        font-size: var(--f-small);
      }
    }
  }

  &:not(.carousel-media) {
    .media-image {
      margin-right: var(--spacing-tighter);
      width: 42%;
    }

    .media-content {
      width: 58%;
    }
  }

  .media-image {
    .image {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 4px;
      padding-top: 60%;
      position: relative;

      .media-timestamp {
        background: var(--shark);
        bottom: 8px;
        color: var(--white);
        padding: var(--spacing-even_tighter) var(--spacing-tightest);
        position: absolute;
        right: 8px;
      }
    }
  }

  .media-content {
    .part-number,
    .media-title {
      font-size: var(--f-normal);
      line-height: var(--line-height-tight);
      margin: 0;
    }

    .part-number {
      color: var(--gray-50);
      margin-top: var(--spacing-tight);
    }

    .media-title {
      font-weight: bold;
    }
  }

  @media screen and (min-width: $md) {
    flex-direction: column;

    .media-image {
      margin: 0 0 var(--spacing-normal);
      width: unset;
    }

    .media-content {
      width: unset;

      .media-title {
        font-size: var(--f-paragraph_large);
      }
    }

    &.carousel-media {
      .media-content {
        .media-title {
          font-size: var(--f-paragraph_large);
        }

        .part-number {
          font-size: var(--f-normal);
        }
      }
    }
  }

  @media only screen and (min-width: $lg) {
    &:not(.carousel-media) {
      .media-image {
        .image {
          height: 141px;
          padding-top: unset;
          width: 236px;
        }
      }
    }
  }
}
