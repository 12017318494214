.embed-item,
.form-wrapper {
  .class-applied-to-form {
    max-width: 576px !important;

    &.container {
      padding: var(--spacing-bit_more_relaxed);
    }

    label {
      color: var(--grayscale);
      display: block;
      font-size: var(--f-paragraph_large);
      font-weight: bold;
      line-height: var(--line-height-normal);
      text-align: left;
    }

    p {
      margin-bottom: 24px;
      margin-top: 0;
    }

    .hs-error-msgs label {
      color: var(--red_again);
      font-size: 13.44px;
      font-style: normal;
      font-weight: normal;
      line-height: 17px;
      margin-bottom: var(--spacing-slight_relax);
    }

    .hs-form-field {
      margin-bottom: var(--spacing-slight_relax);
    }

    fieldset {
      max-width: 100% !important;

      &.form-columns-1,
      &.form-columns-2,
      &.form-columns-3,
      &.form-columns-4 {
        label {
          .hs-form-required {
            color: var(--red_again);
          }
        }

        .hs-error-msg {
          color: var(--red_again);
        }

        input.hs-input {
          border: 1px solid var(--medium_gray) !important;
          border-radius: 4px !important;
          box-sizing: border-box !important;
          font-size: var(--f-paragraph_large) !important;
          padding: 15px !important;
          width: 100% !important;

          &.error {
            border: 1px solid var(--red_again) !important;
          }
        }

        .hs-form-radio label {
          display: flex;
          justify-content: flex-start;
          margin-bottom: 12px;

          input.hs-input {
            display: none;
            width: auto;

            &:checked {
              + span {
                color: var(--card-link);
                font-weight: 600;

                &::before {
                  border: 4px solid var(--card-link);
                }
              }
            }
          }

          span {
            align-items: center;
            color: var(--grayscale);
            display: flex;
            font-size: 20px;
            font-style: normal;
            font-weight: normal;
            line-height: 31px;

            &::before {
              content: '';
              border: 1px solid #000000;
              border-radius: 100%;
              box-sizing: border-box;
              display: inline-block;
              height: 15px;
              margin-right: 12px;
              width: 15px;
            }
          }
        }

        .hs-richtext.hs-main-font-element {
          p {
            color: var(--grayscale);
            font-size: var(--f-paragraph_large);
            font-weight: 400;
            line-height: var(--line-height-normal);
            text-align: left;
          }
        }

        .hs-form-checkbox label {
          color: var(--grayscale);
          font-size: var(--f-paragraph_large);
          font-weight: bold;
          line-height: var(--line-height-normal);

          input.hs-input {
            display: none;
            width: auto;

            &:checked {
              + span {
                color: var(--card-link);
                font-weight: 600;

                &::before {
                  background-color: var(--card-link);
                  border-color: var(--card-link);
                }

                &::after {
                  background-image: url(./../components/Form/Checkbox/check.svg);
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: cover;
                  content: '';
                  height: 11px;
                  left: 2px;
                  position: absolute;
                  top: 11px;
                  width: 11px;
                }
              }
            }
          }

          span {
            align-items: center;
            color: var(--grayscale);
            display: flex;
            font-size: 20px;
            font-style: normal;
            font-weight: normal;
            line-height: 31px;
            position: relative;

            &::before {
              content: '';
              border: 1px solid #000000;
              border-radius: 2px;
              box-sizing: border-box;
              display: inline-block;
              height: 15px;
              margin-right: 12px;
              width: 15px;
            }
          }
        }

        .hs-fieldtype-select {
          .input {
            position: relative;

            &::before {
              content: '';
              background-image: url(../components/Form/Select/angleDown.svg);
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              height: 19px;
              position: absolute;
              right: 15px;
              top: 19px;
              width: 20px;
            }

            > select {
              appearance: none;
              border: 1px solid #c6c6c6;
              border-radius: 4px;
              box-sizing: border-box;
              font-size: var(--f-paragraph_large);
              padding: 15px;
              width: 100%;
              -moz-appearance: none; /* Firefox */
              -webkit-appearance: none; /* Safari and Chrome */
            }
          }
        }
      }
    }

    .hs-form-booleancheckbox-display {
      color: var(--grayscale);
      font-size: var(--f-normal);
      font-weight: bold;
      line-height: var(--line-height-normal);

      input.hs-input {
        display: none;
        width: auto;

        &:checked {
          + span {
            color: var(--card-link);
            font-weight: 600;

            &::before {
              background-color: var(--card-link);
              background-image: url(./../components/Form/Checkbox/check.svg);
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              border-color: var(--card-link);
            }
          }
        }
      }

      > span {
        color: var(--grayscale);
        font-size: var(--f-normal);
        font-weight: normal;
        line-height: var(--line-height-tight);

        &::before {
          border: 1px solid #000000;
          border-radius: 2px;
          box-sizing: border-box;
          content: '';
          display: inline-block;
          height: 15px;
          margin-right: 12px;
          min-width: 15px;
        }
      }

      .hs-form-required {
        font-size: var(--f-paragraph_large);
      }
    }
  }
}

.class-applied-to-form {
  margin: auto;

  .fieldset-columns-1,
  .fieldset-columns-2 {
    margin: auto;
  }

  .hs-error-msgs label {
    color: var(--red_again);
    font-size: 13.44px;
    font-style: normal;
    font-weight: normal;
    line-height: 17px;
    margin-bottom: var(--spacing-slight_relax);
  }

  .btn.btn-primary.btn-medium {
    font-size: medium;
    height: 47px;
    margin: auto;
    min-width: 50%;
    padding-left: 24px;
    padding-right: 24px;
  }

  .hs-form-required {
    color: var(--red_again);
  }
}

.btn-inside-input {
  position: relative;

  form {
    position: relative;
  }

  .hs-email.hs-fieldtype-text.field.hs-form-field {
    margin-bottom: 40px;
  }

  .hs-email label {
    display: none;

    &.hs-error-msg {
      display: block;
    }
  }

  .hs-error-msgs,
  .hs_error_rollup {
    left: 24px;
    position: absolute;

    label {
      color: var(--lc_red);
    }
  }

  .hs-error-msgs {
    left: calc(50% - 118px);
  }

  .hs_error_rollup {
    display: none;

    ul {
      left: calc(50% - 163px);
    }
  }

  .hs-input:focus {
    box-shadow: 0px 0px 0px 2px;
    outline: none;
  }

  .hs-input {
    background: #ffffff;
    border: 1px solid var(--medium_gray);
    border-radius: 47px;
    box-sizing: border-box;
    font-size: 18px;
    line-height: 25px;
    padding: 14px 25px !important;
    width: 100%;
  }
  .hs-submit {
    bottom: 4px;
    position: absolute;
    right: 5px;

    .btn {
      font-size: medium;
      height: 47px;
      min-width: 90px;
    }
  }
}
