@import '../../../styles/_variables.scss';

.content-and-form {
  align-items: start;
  display: flex;
  flex-direction: column;

  .form-wrapper,
  .content-wrapper {
    flex: 100%;
  }

  .content-text {
    font-size: var(--f-paragraph_large);
    margin-bottom: var(--spacing-bit_more_relaxed);
    padding: 0;
  }

  .content-title {
    font-weight: 700;
  }

  .content-collection-title {
    font-size: var(--f-normal);
  }

  .form-wrapper {
    background-color: #f6f6f6;
    border-radius: 8px;
    margin-top: 48px;
    padding: 24px 24px 48px 24px;
    text-align: center;

    .form-title {
      margin-top: 0;
    }

    .form-container {
      .input {
        input {
          padding: 5px;
        }
      }
    }

    .submit-btn {
      width: 100%;
    }
  }
}

.content-wrapper {
  .content-title {
    font-size: var(--f-group_header);
    line-height: var(--line-height-tighter);
    margin-bottom: var(--spacing-more_slight_tight);
  }

  .content-subtitle {
    font-size: var(--f-group_header);
    margin-bottom: var(--spacing-more_slight_tight);
  }

  .anchor-link {
    .path2,
    .path3,
    .path4,
    .path5 {
      &::before {
        color: #00b3e5;
      }
    }
  }
}

@media screen and (min-width: $md) {
  .content-and-form {
    flex-direction: row;

    .content-wrapper {
      flex: 70%;
      margin-right: 16px;
    }

    .form-wrapper {
      flex: 30%;
      margin-top: 0;
      width: 30%;
    }
  }
}

@media screen and (min-width: $lg) {
  .content-and-form {
    .form-wrapper,
    .content-wrapper {
      flex: 50%;
      max-width: 668px;

      .submit-btn {
        width: 332px;
      }
    }
  }

  .content-wrapper {
    .content-title {
      font-size: var(--f-group_header_desktop);
    }
  }
}
