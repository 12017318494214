@import '../../styles/_variables.scss';

.location-static-text {
  padding-left: var(--spacing-normal);
  padding-right: var(--spacing-normal);

  &.hide-mobile-tablet {
    display: none;
  }

  @media screen and (min-width: $lg) {
    padding-left: 0;
    padding-right: 0;

    &.hide-mobile-tablet {
      display: block;
    }
  }

  .location-static-description {
    font-size: var(--f-paragraph_large);
    font-style: normal;
    font-weight: normal;
    line-height: 31.2px;
    margin-bottom: 32px;
  }

  .location-static-title {
    margin-top: 0;
  }
}
