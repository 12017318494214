@import './_font.scss';
@import './_variables.scss';
@import './_helper.scss';
@import './_icons.scss';
@import './_hubspot.scss';
@import './_global.scss';
@import '~mapbox-gl/dist/mapbox-gl.css';
@import './reset';

body {
  font-family: var(--font-family);
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  margin: 0;
  padding-bottom: env(safe-area-inset-bottom);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--black);
}

.text-white {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--white);
  }

  p,
  li,
  ol,
  ul {
    color: var(--gray-10);
  }
}

p {
  color: var(--gray);
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding-left: 0;
  padding-right: 0;
}

img {
  width: 100%;
}

a {
  color: var(--card-link);
  display: inline-block;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

input {
  appearance: none;
}

.container {
  max-width: calc(100% - 32px);
  padding-left: 16px;
  padding-right: 16px;

  @media screen and (min-width: $md) {
    margin-left: auto;
    margin-right: auto;
    max-width: $contentMaxWidth;
  }

  .container {
    max-width: 100%;
  }
}

.align-center {
  margin-left: auto;
  margin-right: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.has-bg-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.imgixBG.dual-contentzone.bg-repeat,
.imgixBG.single-contentzone.bg-repeat {
  background-repeat: repeat;
  background-size: contain !important; // This overrides 'background-size: cover' that comes from imgix.
  width: initial;
}

.display-none-mobile {
  @media screen and (max-width: $md) {
    display: none;
  }
}

p.content-text + div.button-group-wrapper > ul > div {
  margin-top: 0px;
}

.disabled-message-banner {
  align-items: center;
  box-shadow: 0 2px 6px rgba(45, 45, 46, 0.25);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  padding: 24px 16px 12px;
  position: relative;
  text-align: center;
  z-index: 20;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
