@import '../../../styles/_variables.scss';

.about-album-and-songs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 48px;

  .content-wrapper {
    .content-title {
      font-size: 24px;
      line-height: 150%;
    }

    .copy-right-text {
      color: var(--gray-50);
      font-size: 20px;
    }

    .anchor-link {
      .path2,
      .path3,
      .path4,
      .path5 {
        &::before {
          color: #00b3e5;
        }
      }
    }
  }

  .songs-listing-wrapper {
    .songs-heading {
      line-height: 150%;
      margin-bottom: 12px;
      margin-top: 0;
    }

    .songs-listing {
      .song-item {
        border-top: 1px solid #ececec;
        display: flex;
        justify-content: space-between;
        padding: 8px 0;

        &:last-of-type {
          border-bottom: 1px solid #ececec;
        }

        .song-order {
          min-width: 30px;
        }

        .song-periods {
          color: #9f9fa0;
          font-size: 16px;
          font-style: normal;
          font-weight: normal;
          line-height: 150%;
          margin-left: auto;
        }

        .song-name {
          a {
            color: #000;

            &:hover {
              color: var(--card-link) !important;
              text-decoration: none !important;
            }
          }
        }
      }
    }
  }

  .modal {
    max-width: 500px;
    padding: 32px 16px;

    .share-link-modal-content {
      text-align: center;

      .album-image {
        height: 100px;
        margin: auto;
        width: 100px;
      }

      .share-title {
        font-size: 36px;
        font-weight: bold;
        line-height: 160%;
        margin-bottom: 24px;
        margin-top: 0;
      }

      .album-title {
        font-size: 24px;
        font-weight: bold;
        line-height: 160%;
        margin-bottom: 16px;
        margin-top: 8px;
      }

      .link-copy-wrapper {
        position: relative;

        .link-input-box {
          border: 1px solid #efefef;
          border-radius: 47px;
          box-sizing: border-box;
          color: #c6c6c6;
          font-size: 14px;
          height: 50px;
          line-height: 25px;
          padding: 12px 24px;
          width: 100%;
        }

        .btn-copy-link {
          position: absolute;
          right: 5px;
          top: 5px;

          &.copied {
            background: #008bb2;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $md) {
  .about-album-and-songs {
    column-gap: 32px;
    flex-direction: row;

    .content-wrapper,
    .songs-listing-wrapper {
      flex: 50%;
      max-width: 468px;
    }

    .modal {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
