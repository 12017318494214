@import '../../../styles/_variables.scss';

.finds-article-listing {
  background: var(--white);
  padding: 32px 0;

  .button-item-listing.links-group {
    > div {
      grid-gap: 0 !important;
    }
  }

  .link-tertiary {
    margin-right: 16px !important;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

@media screen and (min-width: $lg) {
  .finds-article-listing {
    .container {
      max-width: 1024px;
    }
  }
}
