@import '../../styles/_variables.scss';

body {
  background-color: var(--white) !important;
}

#locations_clip_body {
  background-color: #f2f2f7;
  min-height: 100vh;

  .button {
    border-radius: 6.25rem;
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    line-height: 100%;
    outline: none;
    padding: 0.75rem 2rem;

    &:hover {
      text-decoration: none;
    }
  }

  .button-gray {
    background-color: transparent;
    border: solid 2px var(--gray-50);
    color: var(--black);
  }

  .clip-row {
    display: flex;
    display: -ms-flexbox;
    flex-direction: row;
    -ms-flex-direction: row;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    width: 100%;

    &.center {
      justify-content: center;
      -ms-flex-pack: center;
    }

    &.bottom {
      padding-bottom: 24px;
      padding-top: 4px;

      .button {
        align-items: center;
        display: inline-flex;
        gap: var(--spacing-even_tighter);
        justify-content: center;
        margin-bottom: 0;
        padding-bottom: 8px;
        padding-top: 10px;

        img {
          &.small {
            font-size: 0.75rem;
            height: 28px;
            letter-spacing: -0.00625rem;
            margin-top: -4px;
            width: 28px;
          }
        }
      }
    }

    h1 {
      letter-spacing: -1px;
    }

    h1, p {
      margin: 0;
    }
  }

  .clip-column {
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    -ms-flex-direction: column;
    flex: 1;
    -ms-flex: 1;

    &.clip-columns-2 {
      flex: 2;
      -ms-flex: 2;
    }
  }

  .clip-header {
    background-color: transparent;
    box-sizing: border-box;
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    -ms-flex-direction: column;
    margin-bottom: 0;
    padding: 36px 16px 32px;
    text-align: center;
    width: 100%;

    .clip-row {
      margin: 0 auto;
      max-width: 531px;
    }

    h1 {
      font-size: 21px;
      font-style: normal;
      font-weight: bold;
      line-height: 26px;
      margin-bottom: 0.5rem;
      margin-top: 0;
      width: 100%;
    }

    p {
      color: var(--gray-80);
      font-size: 18px;
      font-style: normal;
      font-weight: normal;
      line-height: 22px;
      margin: 0;
      width: 100%;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .app-card {
    background-color: var(--white);
    border-radius: 12px;
    box-sizing: border-box;
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    -ms-flex-direction: column;
    margin: 16px;
    max-width: 531px;
    padding: 16px 16px 0 16px;

    &.transparent {
      background-color: transparent;
      border-radius: 0;
      padding: 0;

      .app-card-body {
        .app-card-title {
          margin-top: 0px;
        }
      }
    }

    &.content-center {
      text-align: center;
    }

    .app-card-header {
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 8px;
      height: 128px;
      position: relative;
      width: 100%;

      .app-card-secondary-image {
        border-radius: 50%;
        border: 5px solid var(--white);
        bottom: -44px;
        box-shadow: 0 7.65217399597168px 11.478261947631836px 0 #0000001A;
        height: 88px;
        position: absolute;
        right: 5%;
        text-align: center;
        width: 88px;
      }
    }

    .app-card-body {
      padding: 0;

      .app-card-title {
        font-size: 21px;
        font-style: normal;
        font-weight: bold;
        line-height: 26px;
        margin-top: 16px;

        &.has-secondary-image {
          max-width: 60%;
        }
      }

      .app-card-description {
        color: var(--gray-80);
        font-size: 18px;
        font-style: normal;
        font-weight: normal;
        line-height: 22px;
      }
    }

    .app-card-actions {
      border-top: 1px solid #e8e8ee;
      margin-left: -16px;
      width: calc(100% + 32px);

      .app-card-action {
        padding: 18px 16px 16px 20px;
        position: relative;

        a {
          align-items: center;
          color: var(--black);
          display: flex;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
          text-decoration: none;

          &::after {
            background: transparent;
            border-bottom: 0;
            border-left: 0;
            border-right: 2px solid #9a9aa2;
            border-top: 2px solid #9a9aa2;
            content: "";
            height: 12px;
            position: absolute;
            right: 16px;
            top: calc(50% - 6px);
            transform: rotate(45deg);
            width: 12px;
          }
        }

        .app-card-action-icon {
          box-sizing: border-box;
          height: 32px;
          vertical-align: middle;
          width: 32px;

          img {
            vertical-align: baseline;
            width: auto;
          }
        }
      }
    }

    .app-card-action {
      .app-card-action-icon {
        &.desaturate {
          filter: brightness(0) grayscale(1) saturate(0);
        }
      }
    }
  }

  .fns-card {
    background-color: transparent;
    border-radius: 12px;
    display: none;
    height: auto;
    margin: 0 16px;
    padding: 0;
    position: relative;
    text-align: center;
    width: calc(100% - 32px);

    .app-card-header {
      align-items: center;
      background-image: url('https://production-cms-assets.life.church/next-step-cards%2FNew%202%20Tablet.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 8px;
      bottom: 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      height: auto;
      justify-content: flex-end;
      padding: 24px;
      position: relative;
      width: 100%;
    }

    .fns-card-title {
      color: var(--white);
      font-size: 21px;
      font-style: normal;
      font-weight: 700;
      line-height: 26px;
      margin-bottom: 12px;
    }

    .button {
      background: transparent;
      border: 1px solid var(--white);
      color: var(--white);
      font-size: 14px;
      margin: 0;
      padding: 12px 24px;

      &:active,
      &:focus,
      &:link,
      &:hover,
      &:visited {
        border-color: var(--white);
        color: var(--white);
      }
    }
  }

  @media (min-width: $md) {
    .app-card {
      .app-card-header {
        height: 200px;

        .app-card-secondary-image {
          height: 100px;
          width: 100px;
        }
      }
    }

    .fns-card {
      .app-card-header {
        height: auto;
        padding: 40px 40px 40px 214px;
        text-align: left;
        display: block;
      }

      .fns-card-title {
        font-size: 21px;
        line-height: 26px;
        margin-bottom: 16px;
      }

      .button {
        border: 2px solid var(--white);
        font-size: 16px;
        padding: 12px 32px;
      }
    }
  }

}