@import '../../styles/_variables.scss';

.video {
  .container {
    margin: 0 auto;
    padding: 0;

    > div {
      @media screen and (max-width: $md) {
        max-width: 100% !important;
      }
    }
  }

  .video-url {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-flex;
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
    width: 100%;

    &.play {
      .btn-play {
        display: none;
      }

      .video-item {
        z-index: 1;
      }
    }

    .video-item {
      background-color: #000;
      height: 100%;
      left: 0;
      max-width: 100%;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .btn-play {
      display: block;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }
}
