@import '../../../styles/_variables.scss';
.media-collection-listing-album-song-based {
  .collection-listing-album-hero-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 32px;
    position: relative;
    text-align: center;
    width: calc(100% - 64px);

    > * {
      position: relative;
    }

    .image-link,
    .album-detail {
      width: 100%;
    }

    .image-link {
      margin-top: 32px;
      width: 100%;

      .album-image {
        padding-bottom: 100%;
      }
    }

    .album-title,
    .album-sub-title {
      line-height: 150%;
      margin-bottom: 6px;
    }

    .album-year {
      font-size: 24px;
      margin-bottom: 24px;
    }
  }

  @media screen and (min-width: $md) {
    .collection-listing-album-hero-container {
      flex-direction: row;
      max-width: 100%;
      padding: 48px 0;
      width: 100%;

      .image-link,
      .album-detail {
        margin-right: 16px;
        margin-top: 0;
        width: 50%;
      }

      .album-detail {
        margin-left: 16px;
        margin-right: 32px;
      }

      .album-image {
        margin-top: 0;
        max-width: 468px;
        padding-bottom: 468px;
      }

      @media screen and (max-width: $lg) {
        .album-title {
          font-size: 42px;
        }

        .album-sub-title {
          font-size: 20px;
        }
      }

      .btn {
        display: inline-block;
      }
    }
  }

  @media screen and (min-width: $lg) {
    .collection-listing-album-hero-container {
      padding: 98px 0;

      .image-link,
      .album-detail {
        max-width: 468px;
      }

      .album-detail {
        margin-left: 0;
      }

      .image-link {
        margin-right: 0;
      }
    }
  }
}
