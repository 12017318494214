@import '../../styles/_variables.scss';

.location-what-to-expect {
  background-image: url(./assets/what_to_expect_hero_small.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  padding: 247px 0 64px;
  position: relative;

  .bg-video {
    display: none;
  }

  @media screen and (min-width: $md) {
    background-image: url(./assets/what_to_expect_hero_medium.jpg);
    padding-top: 333px;
  }

  @media screen and (min-width: $lg) {
    background-image: url(./assets/what_to_expect_hero_large.jpg);
    padding-top: 64px;

    .bg-video {
      display: block;
      left: 0;
      min-height: 100%;
      object-fit: cover;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
    }
  }

  .container {
    position: relative;
    z-index: 1;

    .title {
      font-size: 31.2px;
      font-style: normal;
      font-weight: bold;
      line-height: 39px;
    }

    .description {
      font-size: 20px;
      font-style: normal;
      font-weight: normal;
      line-height: 31px;
      margin-bottom: 32px;
    }

    .btn-group {
      display: flex;
      flex-direction: column;
      gap: 32px;

      button {
        border-color: #fff;
        color: #fff;

        &:first-child {
          background: #fff;
          color: var(--card-link);
        }
      }

      @media screen and (min-width: $md) {
        flex-direction: row;
      }
    }
  }
}
