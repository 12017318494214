@import '../../styles/_variables.scss';

.location-prelaunched-hero {
  background-color: var(--gray);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 91px 0 137px;
  position: relative;

  .container {
    position: relative;
    z-index: 1;
  }

  .title {
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 900;
    line-height: 100%;
    margin-top: 0;
  }

  .subtitle {
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: 120%;
    margin: 0 0 8px;
  }

  .description {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: normal;
    line-height: 160%;
    margin: 0 auto 32px;
    max-width: 748px;
    width: 75%;
  }

  .btn-hero {
    width: 100%;
  }
}

@media screen and (min-width: $md) {
  .location-prelaunched-hero {
    padding: 150px 0;

    .btn-hero {
      min-width: 328px;
      width: unset;

      &::after {
        height: 100%;
        left: -1px;
        top: -1px;
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: $lg) {
  .location-prelaunched-hero {
    padding: 132px 0;

    .title {
      font-size: 4rem;
      line-height: 61px;
    }

    .subtitle {
      font-size: 2.5rem;
    }

    .description {
      font-size: 2rem;
      line-height: 130%;
    }

    .btn-hero {
      min-width: unset;
    }
  }
}
