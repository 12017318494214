@import '../../styles/_variables.scss';

.single-contentzone {
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  overflow: hidden;
  width: 100%;
  z-index: 0;

  .video-background {
    height: 100%;
    left: 0;
    object-fit: cover;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;

    .youtube-video-wrapper {
      height: 100%;

      > div {
        padding-top: unset;
        position: static;
      }

      .youtube-iframe {
        height: 300%;
        pointer-events: none;
        position: absolute;
        top: -100%;
        width: 100%;

        @media screen and (min-width: $xl) and (max-width: $xxl) {
          width: 150%;
        }

        @media screen and (max-width: $md) {
          height: 600%;
          top: -250%;
          width: 600%;
        }

        @media screen and (min-width: $lg) and (max-width: $xl) {
          height: 400%;
          top: -150%;
          width: 200%;
        }

        @media screen and (min-width: $md) and (max-width: $lg) {
          height: 600%;
          top: -250%;
          width: 300%;
        }
      }
    }
  }

  &.mt-56 {
    margin-top: 56px;
  }

  &.height-70vh {
    height: 70vh;
  }

  .singleIcon {
    border-radius: 100%;
    height: 86px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%);
    width: 86px;

    &.p-10 {
      padding: 10px;
    }

    img {
      border-radius: 100%;
      height: 100%;
      overflow: hidden;
      width: 100%;
    }
  }

  > .container {
    display: flex;
    width: 100%;

    &.content-right {
      justify-content: flex-end;
    }

    @media screen and (min-width: $md) {
      &.full-width {
        > div {
          width: 100%;
        }
      }

      &.twothirds {
        > div {
          width: calc((100% / 3) * 2);
        }
      }
    }

    > div {
      width: 100%;

      .container {
        padding: 0;
      }

      @media screen and (min-width: $md) {
        width: 50%;
      }
    }
  }

  .left-align {
    a:first-child {
      margin-left: 0;
    }
  }

  .right-align {
    a:last-child {
      margin-right: 0;
    }
  }

  &.text-white {
    .accordion-item {
      .accordion-content,
      .accordion-item-head {
        background-color: var(--gray-130) !important;
        border-color: var(--gray-80) !important;
        color: var(--white) !important;

        > div {
          background-color: var(--gray130) !important;
        }

        .rich-text {
          border-color: var(--gray-80) !important;

          *:not(a) {
            color: var(--white) !important;
          }
        }
      }
    }

    .open-content-list-item {
      background-color: var(--gray-130) !important;
      color: var(--white) !important;

      .open-content {
        color: var(--white) !important;

        .card-content,
        .card-title,
        .card-subtitle {
          color: var(--white) !important;
        }
      }
    }
  }
}
