@import '../../styles/_variables.scss';

.banner-wrapper {
  background-color: #f6f6f6;

  .banner-content-and-button {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    padding: 16px 0px;
    text-align: center;

    & > .container {
      padding-left: 0;
      padding-right: 0;
    }

    .banner-content {
      font-size: 20px;
      line-height: 31px;
      margin: 0 0 16px;

      p {
        color: var(--gray);
        margin: 0;
      }
    }

    .btn {
      line-height: var(--line-height-tightest);
      white-space: nowrap;

      &.text-decoration-none {
        text-decoration: none;
      }
    }
  }
}

@media screen and (min-width: $md) {
  .banner-wrapper {
    .banner-content-and-button {
      align-items: center;
      column-gap: 32px;
      flex-direction: row;
      justify-content: space-between;
      max-width: 573px;
      text-align: left;

      & > .container {
        padding-left: 16px;
        padding-right: 16px;
      }

      .banner-content {
        margin: 0;
      }

      .btn {
        line-height: 14px;
      }
    }
  }
}

@media screen and (min-width: $lg) {
  .banner-wrapper {
    .banner-content-and-button {
      max-width: 1200px;

      .banner-content {
        p {
          font-style: var(--f-paragraph_large);
          line-height: 31px;
        }
      }
    }
  }
}
