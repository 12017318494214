.in-this-series {
  .title {
    font-size: var(--f-group_header);
    line-height: 150%;
    margin-bottom: var(--spacing-more_slight_tight);
    margin-top: 0;
  }

  @media screen and (min-width: $lg) {
    .title {
      font-size: 28px;
    }
  }

  @media screen and (max-width: $lg) {
    &.full-size {
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;

      h2 {
        padding-left: 16px;
        padding-right: 16px;
      }

      .slider {
        .slider-frame {
          .slider-list {
            margin-left: 16px !important;
          }
        }
      }
    }
  }
}
