@import '../../../styles/_variables.scss';

.podcast-collection-content-form {
  display: flex;
  flex-direction: column;
  padding-left: 0 !important;
  padding-right: 0 !important;

  .collection-form {
    background-color: #f6f6f6;
    box-sizing: border-box;
    margin-bottom: 32px;
    padding: 24px 16px 32px;

    .title {
      font-size: 24px;
      font-style: normal;
      font-weight: bold;
      line-height: 150%;
      margin: 0 0 12px;
      text-align: center;
    }

    .description {
      font-size: 20px;
      font-style: normal;
      font-weight: normal;
      line-height: 150%;
      margin: 0 0 20px;
      text-align: center;
    }
  }

  .collection-content {
    padding: 32px 16px;

    .title {
      font-size: 24px;
      font-style: normal;
      font-weight: bold;
      line-height: 39px;
    }

    .description {
      font-size: 20px;
      font-style: normal;
      font-weight: normal;
      line-height: 31px;
    }

    .button-group-wrapper .share-links-group > div {
      .icon {
        .path2::before {
          color: rgba(75, 165, 211, 0.2);
        }

        .path3::before {
          color: var(--card-link-primary-lighter);
        }
      }
    }
  }
}

@media screen and (min-width: $md) {
  .podcast-collection-content-form {
    flex-direction: row;

    .collection-form,
    .collection-content {
      margin-bottom: 0;
      width: 50%;
    }

    .collection-form {
      .description {
        margin-bottom: 36px;
      }

      .btn-inside-input {
        margin-left: auto;
        margin-right: auto;
        max-width: 343px;
      }
    }
  }
}

@media screen and (min-width: $lg) {
  .podcast-collection-content-form {
    padding-left: 16px !important;
    padding-right: 16px !important;

    .collection-form {
      border-radius: 12px;
    }
  }
}
