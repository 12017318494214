@import '../../styles/_variables.scss';

.menu-modal {
  background-color: rgba(255, 255, 255, 0.97);
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 100%;
  z-index: 9999;

  .modal-navigation {
    margin-bottom: var(--spacing-normal);

    &:last-child {
      margin-bottom: var(--spacing-very_relaxed);
    }

    .modal-nav-title {
      font-size: var(--f-group_header);
      font-weight: bold;
      line-height: var(--line-height-normal);
      margin: 0 0 var(--spacing-tighter);
      padding: 0 var(--spacing-normal);
    }

    > ul {
      > li {
        a {
          border-bottom: 1px solid #ddd;
          color: #444;
          display: flex;
          flex-direction: column;
          font-weight: 600;
          line-height: var(--line-height-normal);
          padding: var(--spacing-slight_tight) var(--spacing-normal);
          text-decoration: none;

          &:hover {
            background-color: #e7faff;

            .modal-link-title {
              color: var(--card-link-primary-active);
            }
          }

          .modal-link-title {
            color: var(--card-link);
            font-size: var(--f-normal);
            font-weight: 700;
          }

          .modal-link-description {
            color: #444;
            font-size: var(--f-normal);
            font-weight: var(--fw-regular);
            margin: 0;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $lg) {
  .menu-modal {
    .modal-navigation {
      border-bottom: 1px solid #ddd;
      margin: 0 auto;
      margin-bottom: var(--spacing-bit_more_relaxed) !important;
      max-width: 62.5em;
      padding: var(--spacing-normal) 0;

      &:last-child {
        border-bottom: none;
      }

      > ul {
        > li {
          a {
            border-bottom: none;
            flex-direction: row;
            font-weight: 300;
            padding: 0.8rem var(--spacing-normal);

            .modal-link-title {
              flex-basis: 22%;
              margin-right: 4%;
            }

            .modal-link-description {
              flex-basis: 74%;
            }
          }
        }
      }
    }
  }
}
