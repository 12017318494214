@import '../../../styles/_variables.scss';

.location-hero-v2 {
  height: calc(70vh - 81px);
  position: relative;

  .hero-bg {
    height: 100%;
    left: 0px;
    max-width: 100%;
    object-fit: cover;
    object-position: top;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: -1;
  }

  .container {
    height: 100%;
  }

  .content {
    margin: auto;
    max-width: 450px;

    p {
      font-size: 24px;
      font-weight: 400;
      line-height: var(--line-height-tight);
      margin-bottom: 16px;
    }

    .icon {
      height: 20px;
      width: 20px;
    }
  }

  .videoModal {
    align-items: center;
  }
}

@media screen and (min-width: $lg) {
  .location-hero-v2 {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);

    .content {
      max-width: 600px;

      p {
        font-size: 32px;
        margin-bottom: 32px;
      }
    }
  }
}