@import '../../styles/_variables.scss';

.location-detail {
  p {
    font-size: 16px;
    font-style: normal;
    line-height: 100%;
    margin: 0 0 12px;
  }

  .singleIcon {
    border-radius: 100%;
    height: 96px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%);
    width: 96px;
  }

  .text-feature_paragraph {
    font-size: 24px;
    font-style: normal;
    font-weight: normal;
    line-height: 120%;
    text-align: center;
  }

  .location-section-title {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    margin-top: 0;
    text-align: center;
  }

  .link {
    align-items: center;
    column-gap: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: $lg) {
  .location-detail {
    .location-section-title {
      font-size: 48px;
    }
  }
}

.location-card {
  .open-content-list {
    .open-content-list-item {
      .open-content {
        .card-title {
          text-align: left;
        }
      }
    }
  }
}

.location-launched-v2 {
  h1, h2, h3, h4, p, span {
    margin: 0
  }

  .close-wrapper {
    background-color: transparent;
  }

  .location-modal {
    .modal {
      align-items: center;
      border-radius: 0;
      display: flex;
      height: calc(100vh - 32px);
      justify-content: center;
      margin: 0 auto;
      max-width: 500px;
      width: 100%;
    }
  }
}

@media screen and (min-width: $md) {
  .location-launched-v2 {
    .location-modal {
      .modal {
        height: calc(100vh - 48px);
      }
    }
  }
}
