@import '../../../styles/_variables.scss';

.church-resources {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  padding: 64px 0;

  @media screen and (max-width: $md) {
    height: 680px;
  }

  .container {
    display: flex;

    .church-resources-title {
      color: white;
      line-height: 100%;
      margin-top: 0;
    }

    .church-resources-desc {
      color: var(--light_gray);
    }
  }
}

@media screen and (min-width: $md) {
  .church-resources {
    .container {
      display: flex;
      justify-content: flex-end;
    }

    .church-resources-content {
      max-width: 584px;
      width: 50%;

      @media screen and (max-width: $lg) {
        .btn {
          flex-grow: 1 !important;
          max-width: 100% !important;
        }
      }
    }
  }
}
