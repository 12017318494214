@import '../../../styles/_variables.scss';

.podcast-quote-modal {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;

  .modal-header {
    background-color: #404041;
    display: flex;
    height: 50px;
    justify-content: flex-end;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    .btn-exit {
      svg {
        height: 18px;
        width: 18px;
      }
    }
  }

  .container {
    background-color: #fff;
    box-sizing: border-box;
    padding: 0;
    width: 100%;

    .bg-img {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding-top: 124.8%;
    }

    .modal-footer {
      background: #fff;
      display: flex;
      height: 100px;

      .w-75 {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: auto;
        width: 75%;
      }
    }
  }
}

@media screen and (min-width: $md) {
  .podcast-quote-modal {
    .modal-header {
      height: 64px;

      .btn-exit {
        svg {
          height: 32px;
          width: 32px;
        }
      }
    }

    .container {
      border-radius: 8px;
      max-width: 500px;
    }
  }
}

@media screen and (min-width: $lg) {
  .podcast-quote-modal {
    .container {
      max-width: 650px;
    }
  }
}
