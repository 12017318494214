@import '../../../styles/_variables.scss';

.podcast-text-image {
  display: flex;
  flex-direction: column;

  .podcast-image {
    margin-bottom: var(--spacing-bit_more_relaxed);
    margin-right: 0;
  }

  .podcast-image .image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
    padding-top: 58.31%;
  }

  .podcast-text {
    .title,
    .subtitle,
    .description {
      font-style: normal;
      font-weight: normal;
      margin: 0 0 8px;
    }

    .title {
      font-size: 32px;
      font-weight: 900;
      line-height: 150%;
    }

    .subtitle {
      font-size: 24px;
      line-height: 120%;
    }

    .description {
      font-size: 16px;
      line-height: 150%;
    }

    .podcast-button {
      display: flex;
      flex-direction: column;

      .btn {
        margin-top: var(--spacing-more_slight_tight);
        width: 100%;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}

@media screen and (min-width: $md) {
  .podcast-text-image {
    flex-direction: row;

    .podcast-image,
    .podcast-text {
      width: 50%;
    }

    .podcast-image {
      margin-bottom: 0;
      margin-right: var(--spacing-bit_more_relaxed);
    }

    .podcast-text {
      .podcast-button {
        display: block;

        .btn {
          display: inline-block;
          margin-top: var(--spacing-more_slight_tight);
          width: auto;

          &:first-child {
            margin-right: var(--spacing-more_slight_tight);
          }
        }
      }
    }
  }
}

@media screen and (min-width: $lg) {
  .podcast-text-image {
    .podcast-image {
      width: 41.67%;
    }

    .podcast-text {
      .title,
      .subtitle {
        margin-bottom: 12px;
      }

      .description {
        margin-bottom: 8px;
      }
    }
  }
}
