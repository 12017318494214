@import '../../../../styles/_variables.scss';

.multi-step-form {
  .input-item-wrapper {
    width: 100%;

    p {
      margin-bottom: 0;
      margin-top: 1.5rem;
    }
  }

  @media only screen and (min-width: $lg) {
    .small-header {
      margin-bottom: 7px;
    }
  }
}
