@import '../../../styles/_variables.scss';
.current-series {
  &.episode-based {
    background-color: #f4f4f4;
    margin-bottom: 100px;

    .current-series-wrapper {
      display: grid;

      @media screen and (min-width: 641px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: minmax(1px, auto) minmax(1px, auto) 1fr;

        .current-series-sub-title {
          margin-top: 0;
        }

        .current-series-title,
        .current-series-sub-title,
        .current-series-desc {
          max-width: 492px;
        }

        .image-link {
          grid-column: 2;
          grid-row: 1 / span 3;
          padding: 0 32px;
        }

        > * {
          grid-column: 1;
        }
      }

      @media screen and (min-width: $lg) {
        grid-template-columns: auto auto;
      }
    }

    @media screen and (max-width: $md) {
      padding-bottom: 16px;
      padding-top: 16px;

      .image-link {
        grid-row: 1;
        margin-bottom: 36px;
      }

      .current-series-sub-title,
      .current-series-desc {
        display: block;
      }

      .current-series-sub-title {
        font-size: 18px;
        line-height: 100%;
      }
    }
  }
}

.current-series-wrapper {
  padding: 0;

  .current-series-title {
    line-height: 100%;
  }

  .current-series-title,
  .current-series-sub-title,
  .current-series-desc {
    margin-bottom: 12px;
    margin-top: 0;
  }

  .current-series-sub-title,
  .current-series-desc {
    display: none;
  }

  .current-series-desc {
    font-size: 18px;
  }

  .current-series-image {
    object-fit: cover;
    width: 100%;
  }
}

@media screen and (min-width: $md) {
  .current-series-wrapper {
    .current-series-title {
      font-size: 24px;
    }

    .current-series-sub-title {
      font-size: 20px;
      margin-top: 37px;
    }

    .current-series-sub-title,
    .current-series-desc {
      display: block;
    }
  }
}

@media screen and (min-width: $lg) {
  .current-series-wrapper {
    align-items: start;
    column-gap: 16px;
    display: grid;
    grid-template-columns: auto 660px;
    grid-template-rows: minmax(1px, auto) minmax(1px, auto) 1fr;

    .current-series-title {
      font-size: 42px;
      margin-bottom: 0;
    }

    .current-series-sub-title {
      font-size: 28px;
      margin-top: 12px;
    }

    .current-series-image {
      max-width: 660px;
      width: 100%;
    }

    .image-link {
      grid-column: 2;
      grid-row: 1 / span 3;
    }

    > * {
      grid-column: 1;
    }
  }
}
