@import '../../styles/_variables.scss';

.copy-this-text-container {
  .copy-this-text {
    background-color: var(--gray-5);
    border-radius: 8px;
    color: var(--black);
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 30px;
    padding: 16px 24px;

    p {
      font-size: 20px !important;
    }
  }

  .btn {
    font-size: 14px;
    font-weight: 600;
    margin: 0 auto;
    max-width: 320px;

    &.icon-to-right {
      .button-text {
        padding: 0 1.5rem 0 0 !important;
      }
    }

    &.copied {
      cursor: default;
      pointer-events: none;
    }
  }

  &.dark-mode {
    .copy-this-text {
      background-color: var(--gray-130);
      color: var(--white);

      p {
        color: var(--white);
      }
    }
  }
}
