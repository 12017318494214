@import '../../styles/_variables.scss';

.lco-on-demand-banner {
  background-color: var(--black);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: auto 100%;
  bottom: 0;
  color: var(--white);
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  gap: var(--spacing-normal);
  justify-content: space-evenly;
  left: 0;
  padding: var(--spacing-relaxed);
  position: fixed;
  text-align: left;
  width: calc(100% - calc(var(--spacing-relaxed) * 2));
  z-index: 999998;

  .banner-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-normal);
  }

  .banner-ctas {
    flex-shrink: 0;
    width: 100%;

    .btn {
      width: 100%;
    }
  }

  .banner-text-labels {
    align-items: center;
    display: flex;
    gap: var(--spacing-normal);
    justify-content: space-between;
  }

  .banner-badge {
    .badge {
      border-radius: calc(var(--radius-xs) / 2);
      display: inline-block;
      font-size: var(--xsmall);
      opacity: 1;
      padding: calc(var(--spacing-even_tighter) / 1.5)
        calc(var(--spacing-even_tighter) * 1.5);
      text-transform: uppercase;
      width: auto;
    }
  }

  .banner-timer {
    display: flex;
    gap: var(--spacing-tighter);
    padding: 0 var(--spacing-tighter);

    .timer-column {
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    .timer-labels {
      font-size: var(--f-xsmall);
    }

    .timer-numerals {
      font-size: var(--f-feature_paragraph_desktop);
      font-weight: var(--fw-black);
      line-height: var(--line-height-tighter);
    }
  }

  .banner-title {
    font-size: var(--f-feature_paragraph);
    font-weight: var(--fw-bold);
    line-height: var(--line-height-tighter);
    margin: 0;
    width: 60%;
  }

  .btn-banner-close {
    background-color: transparent;
    border: none;
    cursor: pointer;
    height: var(--spacing-bit_more_relaxed);
    outline: none;
    padding: var(--spacing-even_tighter);
    position: absolute;
    right: var(--spacing-none);
    top: var(--spacing-none);
    width: var(--spacing-bit_more_relaxed);

    .icon {
      display: block;
      height: var(--spacing-normal);
      left: var(--spacing-tighter);
      position: relative;
      top: var(--spacing-none);
      transition: all 0.3s ease-in-out;
      width: var(--spacing-normal);

      &::after {
        border-left: 2px solid var(--gray-50);
        content: '';
        height: var(--spacing-normal);
        left: var(--spacing-even_tighter);
        position: absolute;
        transform: rotate(45deg);
      }

      &::before {
        border-left: 2px solid var(--gray-50);
        content: '';
        height: var(--spacing-normal);
        left: var(--spacing-even_tighter);
        position: absolute;
        transform: rotate(-45deg);
      }

      &:hover {
        &::after,
        &::before {
          border-left-color: var(--white);
        }
      }
    }
  }

  @media screen and (min-width: $md) {
    background-image: none;
    border-radius: var(--radius-s);
    bottom: var(--spacing-normal);
    left: var(--spacing-normal);
    // Choosing explicit math calculation to ensure same as width value.
    max-width: calc(800px - 254px);
    padding: var(--spacing-bit_more_relaxed) 40px
      var(--spacing-bit_more_relaxed) 214px;
    width: calc(100% - 254px);

    .banner-content {
      flex-direction: row;
      gap: var(--spacing-relaxed);
      justify-content: space-between;
    }

    .banner-ctas {
      width: auto;
    }

    .banner-title {
      font-size: var(--f-feature_paragraph_desktop);
    }
  }
}