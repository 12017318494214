$contentMaxWidth: 1200px;

// Break point
$md: 640px;
$lg: 1024px;
$xl: 1200px;
$xxl: 1600px;

// Base
$linkColor: #009ecc;
