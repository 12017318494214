.form-control {
  .radio {
    border: 1px solid #000000;
    border-radius: 100%;
    box-sizing: border-box;
    display: inline-block;
    height: 15px;
    margin-right: 12px;
    width: 15px;

    img {
      display: none;
    }

    &:checked {
      background-color: red;

      + .radio {
        border: 4px solid var(--card-link);

        + .label-text {
          color: var(--card-link);
          font-weight: 600;
        }

        img {
          display: flex;
          height: 11px;
          margin: 2px auto 0;
          width: 11px;
        }
      }
    }
  }

  .label-text {
    color: var(--grayscale);
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    line-height: 31px;
  }

  .display-none {
    display: none !important;
  }
}
