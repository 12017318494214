@import '../../../styles/_variables.scss';

.location-download-v2 {
  .container {
    background-image: url(../../LocationV2/assets/SectionDownloadSmall.png);
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 72px 16px;
  }

  .content {
    align-items: center;
    margin: auto;
    max-width: 450px;

    img {
      height: 120px;
      width: 120px;
    }
  }
}

@media screen and (min-width: $md) {
  .location-download-v2 {
    .container {
      background-image: url(../../LocationV2/assets/SectionDownloadMedium.png);
    }
  }
}

@media screen and (min-width: $lg) {
  .location-download-v2 {
    .container {
      background-image: url(../../LocationV2/assets/SectionDownloadLarge.png);
    }

    .content {
      margin: 0px;
      max-width: 100%;
      width: 552px;

      h2 {
        width: 290px;
      }
    }
  }
}
