@import '../../styles/_variables.scss';

.tabs {
  background-color: var(--gray-10);
  border-radius: 50px;
  display: flex;
  padding: var(--spacing-even_tighter);

  .tab {
    border-radius: 50px;
    color: var(--grayscale);
    flex-grow: 1;
    font-size: var(--f-normal);
    line-height: 20px;
    padding: 7px 14px;

    &[aria-selected='true'] {
      background-color: var(--white);
      color: var(--black);
    }
  }
}
