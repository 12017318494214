@import '../../styles/_variables.scss';

.accordion {
  .accordion-listing {
    overflow: hidden;
    .accordion-showmore {
      color: var(--card-link);
      font-family: var(--font-family);
      font-size: 20px;
      font-style: normal;
      font-weight: bold;
      width: 100%;

      &:disabled {
        opacity: 0.5;
      }
    }
  }

  @media only screen and (min-width: $md) {
    max-width: 800px !important;
  }
}
