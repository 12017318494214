@import '../../../styles/_variables.scss';

.location-banner {
  &.sticky-banner {
    bottom: 0;
    position: sticky;
  }

  .banner-container {
    align-items: center;
    column-gap: 64px;
    display: flex;
    justify-content: center;
    padding: 24px 0px;

    h3 {
      display: none;
      font-size: 32px;
      font-weight: 700;
      line-height: var(--line-height-tighter);
      margin: 0;
    }

    .icon {
      height: 24px;
      width: 24px;
    }
  }
}

@media screen and (min-width: $lg) {
  .location-banner {
    .banner-container {
      h3 {
        display: block;
      }
    }
  }
}