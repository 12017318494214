.form-control {
  .active {
    .label-text {
      color: var(--card-link);
      font-weight: 600;
    }

    .checkbox {
      background-color: var(--card-link);
      border-color: var(--card-link);

      img {
        display: flex;
        height: 11px;
        margin: 2px auto 0;
        width: 11px;
      }
    }
  }

  .checkbox {
    border: 1px solid #000000;
    border-radius: 2px;
    box-sizing: border-box;
    display: inline-block;
    height: 15px;
    margin-right: 12px;
    width: 15px;

    img {
      display: none;
    }
  }

  .label-text {
    color: var(--grayscale);
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    line-height: 31px;
  }
}
