@import '../../styles/_variables.scss';

.homepage-hero {
  .single-contentzone {
    box-sizing: border-box;
    max-height: 900px;
    min-height: 70vh;
  }

  .open-content-list {
    margin-top: -0.8125rem;
    position: relative;
    z-index: 1;

    @media screen and (max-width: $lg) {
      &.content-horizontal-cards > div {
        grid-template-columns: unset;
        margin-left: auto;
        margin-right: auto;
        max-width: 504px;
      }
    }

    .open-content-list-item {
      height: initial;
      width: initial;
    }
  }

  .text-title {
    p {
      line-height: inherit;
    }
  }
}
