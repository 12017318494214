@import '../../../styles/_variables.scss';

.location-schedule-v2 {
  padding-bottom: 48px;
  padding-top: 40px;

  .schedule-header {
    margin: auto;
    margin-bottom: 32px;
    max-width: 450px;

    h1 {
      font-size: 32px;
      font-weight: 900;
      line-height: var(--line-height-tighter);
      white-space: pre-wrap;
    }
  }

  .content {
    margin: auto;
    max-width: 450px;

    >h2 {
      font-size: 24px;
      font-weight: 700;
      line-height: var(--line-height-tighter);
      margin-bottom: 8px;
    }

    >p {
      font-size: 16px;
      font-weight: 400;
      line-height: var(--line-height-normal);
      margin-bottom: 24px;
    }

    .schedule-service-times {
      margin-bottom: 24px;

      &:last-of-type {
        margin-bottom: 0px;
      }

      h3 {
        font-size: 16px;
        font-weight: 700;
        line-height: var(--line-height-normal);
        margin-bottom: 8px;
      }

      >div {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        span {
          font-size: 16px;
          font-weight: 700;
          line-height: var(--line-height-normal);
          padding: 4px 8px;
        }
      }
    }

    .schedule-btn {
      margin-top: 32px;
      width: 100%;
    }
  }
}

.modal-open {
  .contact-card-address {
    margin-top: 40px;

    >div {
      align-items: center;
      column-gap: 8px;
      display: flex;
      margin-bottom: 4px;

      .icon {
        font-size: 24px;
      }
    }

    h3 {
      font-size: 16px;
      font-weight: 700;
      line-height: var(--line-height-normal);
    }

    a:hover {
      text-decoration: none;
    }

    p {
      color: inherit;
      font-size: 16px;
      font-weight: 400;
      line-height: var(--line-height-normal);
      margin-bottom: 8px;
      padding-left: 40px;
    }
  }
}

@media screen and (min-width: $lg) {
  .location-schedule-v2 {
    &:not(.modal-open) {
      padding: 112px 0px;

      .container {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }

      .schedule-header {
        margin: 0;
        max-width: 700px;

        h1 {
          font-size: 64px;
          line-height: var(--line-height-tightest);
        }
      }

      .content {
        margin: 0;
        max-width: 345px;

        h2 {
          font-size: 32px;
        }

        p {
          font-size: 20px;
        }

        .schedule-service-times {
          h3 {
            font-size: 24px;
            line-height: var(--line-height-tighter);
          }
        }
      }
    }
  }

  .modal-open {
    padding: 0px;

    .schedule-header {
      h1 {
        font-size: 40px;
        font-weight: 900;
        line-height: var(--line-height-tighter);
        text-align: left;
      }
    }

    .content {
      h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: var(--line-height-tighter);
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: var(--line-height-tightest);
      }
    }
  }
}
