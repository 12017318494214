@import '../../styles/_variables.scss';
@import '../../styles/_font.scss';

.countdown-timer {
  width: 100%;

  .timer-value {
    display: inline-block;
    padding: 8px;

    > span {
      display: block;
      margin: auto;
      text-align: center;

      &.value-field {
        font-weight: 900;
      }

      &.value-name {
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  &.text-group_header {
    .timer-value {
      padding: 6px;

      > span {
        &.value-name {
          font-size: 14px;
        }
      }
    }
  }

  &.text-title {
    .timer-value {
      padding: 12px;

      > span {
        &.value-name {
          font-size: 18px;
        }
      }
    }
  }

  &.text-huge {
    .timer-value {
      padding: 16px;

      > span {
        &.value-name {
          font-size: 24px;
        }
      }
    }
  }
}
