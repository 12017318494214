.accordion-item {
  &:last-child {
    border-bottom: none;
  }

  .accordion-item-head {
    align-items: center;
    background-color: var(--white);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid var(--gray-10);

    .accordion-item-title {
      font-family: var(--font-family);

    }

    svg {
      flex-shrink: 0;
      transform: rotate(0);
      transition: 0.3s ease-out;
    }
  }

  &.has-content {
    &.active,
    &:hover {
      .accordion-item-head {
        background-color: #F2FAFC;
        color: var(--card-link);
      }
    }

    &.active {
      .accordion-item-head svg {
        transform: rotate(180deg);
      }
    }
  }
  .accordion-content {
    height: 0;
    overflow: hidden;
    transition: height 0.3s;

    .rich-text {
      display: flex;
      flex-direction: column;
      padding: 16px !important;
      border-bottom: 1px solid var(--gray-10);
    }
  }

  .item-link {
    color: var(--black);
    display: block;

    &:hover {
      color: var(--card-link-primary-lighter);
      text-decoration: none;

      .play-icon {
        path {
          fill: var(--blue);
        }
      }
    }
  }
}
