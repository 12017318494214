.richtext {
  display: flex;
  flex-direction: column;
  text-align: left;

  label {
    color: var(--grayscale);
    font-size: var(--f-paragraph_large);
    font-weight: bold;
    line-height: var(--line-height-normal);
  }

  textarea {
    border: 1px solid #c6c6c6;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: var(--f-paragraph_large);
    padding: 15px;
  }

  span {
    color: #666667;
    font-size: 13.44px;
    font-style: normal;
    font-weight: normal;
    line-height: 17px;
  }
}
