@import '../../../styles/_variables.scss';

.podcast-hero {
  background-color: #515151;
  position: relative;

  .container {
    padding-left: 0;
    padding-right: 0;

    .video-wrapper {
      padding-top: 56.25%;
      position: relative;
    }
  }

  .video-podcast {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .youtube-iframe {
    height: 100% !important;
    min-height: 100% !important;
  }

  .btn-play {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
}
