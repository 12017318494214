@import '../../styles/_variables.scss';

.search-form {
  height: 91vh;

  @media screen and (min-width: $md) {
    height: calc(100vh - 305px);
  }
  @media screen and (min-width: $lg) {
    height: calc(100vh - 235px);
  }

  .embed-item {
    height: 100%;
    max-height: 100%;
    max-width: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}
